import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'



const getNotes = () => {
    var notes = ref([])
    const errorNote = ref(null)
    //const idNote = id

    const loadNote = async(idNote) => {
      try{

        projectFirestore.collection('Notes').orderBy('DateAdded', "desc")
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          notes.value = docs
          //var i = notes.value.length
          var i = 0

         while (i < notes.value.length) {
           //console.log(notes.value.length)
           //console.log(idNote)
              if(notes.value[i].childId === idNote){
                
                 
              
              }else{
                notes.value.splice(i,1)
                i--
              }
              i++
          }
          i = 0

          
        })
        
         



        


      }
      catch(err){
        errorNote.value = err.message    
        console.log(errorNote.value)    
      }

    }
    return{ notes, errorNote, loadNote }
}

export default getNotes