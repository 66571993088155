<template>
<div class="center">
  <div class="row center">
    <div class="col s12 m6">
      <div class="card blue-grey">
        <div class="card-content white-text">
          <span class="card-title center">Add a New Student</span>
          <p class="center">Use this area to add details of a new student.</p>
        </div>
        <router-link :to="{name: 'AddStudent'}"><div class="card-action center blue-grey darken-2">
          <h5 class="orange-text">Add Student</h5>
                  </div></router-link>
      </div>
    </div>
    <div class="col s12 m6">
      <div class="card blue-grey">
        <div class="card-content white-text">
          <span class="card-title center">View All Students</span>
          <p class="center">Use this area to view details of existing students and submit reports.</p>
        </div>
        <router-link :to="{name: 'ViewAllStudents'}"><div class="card-action center blue-grey darken-2">
          <h5 class="orange-text">View Students</h5>
                  </div></router-link>
      </div>
    </div>
  </div>
  
    <div v-if="checkWhere(user)" class="row">

        <div v-if="checkWhere(user)" class="row">
    <div class="col s12 m6">
      <div class="card blue-grey">
        <div class="card-content white-text">
          <span class="card-title center">New Receipt</span>
          <p class="center">Generate a new charitable donation receipt.</p>
        </div>
        <router-link :to="{name: 'NewReceipt'}"><div class="card-action center blue-grey darken-2">
          <h5 class="orange-text">New Receipt</h5>
                  </div></router-link>
                  </div>
                  </div>
     <div class="col s12 m6">
      <div class="card blue-grey">
        <div class="card-content white-text">
          <span class="card-title center">New Transfer Request</span>
          <p class="center">Request a new transfer to Kenya</p>
        </div>
        <router-link :to="{name: 'NewTransfer'}"><div class="card-action center blue-grey darken-2">
          <h5 class="orange-text">New Transfer</h5>
                  </div></router-link>
                  </div>
    
      </div>
    
  
  </div>
  <div v-if="checkWhere(user)" class="row">
    <div class="col s12 m6">
      <div class="card blue-grey">
        <div class="card-content white-text">
          <span class="card-title center">Tree Project</span>
          <p class="center">View tree project data.</p>
        </div>
        <a href="https://icfem.retool.com/embedded/public/310631c1-d3a7-4bb0-a30b-17cf4e90b52e"><div class="card-action center blue-grey darken-2">
          <h5 class="orange-text">View Project</h5>
                  </div></a>
                  </div>
  </div>
  </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from '@vue/reactivity'
import { projectAuth } from '../firebase/config'
import getUser from '../Composables/getUser'
import { onBeforeMount, onMounted } from '@vue/runtime-core'
import router from '../router'

export default {
  name: 'Home',
  setup(){

    onBeforeMount(() => {
      if(!projectAuth.currentUser){
        router.push({name: 'Login'})
      }
    })


    const uk = ref(true)
    const { user } = getUser()

    const checkWhere = (cred) => {
      if(cred){
      var split = cred.email.split("@")
      if(split[1] === "icfem-mission.org"){
        return(true)
      }else{
        return(false)
      }
    }else{
      return(false)
    }
    }

     




    return{ uk, user, checkWhere }
  }
}
</script>

<style scoped>

</style>
