import { createApp } from 'vue'
import App from './App.vue'
import { projectAuth } from './firebase/config'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'



let app

projectAuth.onAuthStateChanged(() => {
    if(!app){

        app = createApp(App)
        .use(router)
        .use(VueGoogleMaps, {
            load: {
                key: 'AIzaSyDLvaUkytO-bySfUT4hodvH18-kv9d3b-w',
            },
            installComponents: true})
        .mount('#app')
    }
})


