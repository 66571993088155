<template>
  <div class="center"><h5>View All Reports</h5></div>

  <div class="row">
        <ReportList :reports = "reports"/>
  </div>
</template>

<script>
import getReports from '../Composables/getReports'
import ReportList from '../components/ReportList.vue'
export default {
    components: {ReportList},
    setup() {
        const {reports, error, load} = getReports()
        load()

        


        return{
            reports,
            error

        }
    }

}
</script>

<style>

</style>