import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import AddStudent from '../views/AddStudent.vue'
import AddConsent from '../views/AddConsent.vue'
import ViewAllStudents from '../views/ViewAllStudents.vue'
import Hubspot from '../views/Hubsot.vue'
import viewAStudent from '../views/viewAStudent.vue'
import Notes from '../components/Notes.vue'
import AddReport from '../views/AddReport.vue'
import Login from '../views/auth/login.vue'
import viewAllReports from '../views/viewAllReports.vue'
import { projectAuth } from '../firebase/config'
import Tree from '../views/Trees/PlantTree.vue'
import ReportListMain from '../components/ReportListMain.vue'
import NewReceipt from '../views/NewReceipt.vue'
import NewTransfer from '../views/NewTransfer.vue'


//auth guard

const requireAuth = (to, from, next) => {

  let user = projectAuth.currentUser
  console.log("current user in Auth Guard: "+user)
  if(!user){
    next({name: 'Login'})
  }else{
    next()
  }
  

}

const checkLocation = (to, from, next) => {

  let user = projectAuth.currentUser
  console.log("current user in Auth Guard: "+user.email)
  if(!user){
    next({name: 'Login'})
  }

  var split = user.email.split("@")

  if(split[1] === "icfem-mission.org"){
    next()
  }else{
    next({name: "Home"})
  }




}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/Trees',
    name: 'PlantATree',
    component: Tree
  },

  {
    path: '/AddStudent',
    name: 'AddStudent',
    component: AddStudent, 
    beforeEnter: requireAuth
  },
  {
    path: '/ViewAllStudents',
    name: 'ViewAllStudents',
    component: ViewAllStudents,
    beforeEnter: requireAuth
  },
  {
    path: '/ViewAllReports',
    name: 'ViewAllReports',
    component: viewAllReports,
    beforeEnter: requireAuth
  },
  {
    path:'/Hubspot',
    name: 'Hubspot',
    component: Hubspot,
    beforeEnter: checkLocation
  },
  {
    path:'/ReportCardMain',
    name: 'ReportCardMain',
    component: ReportListMain,
    beforeEnter: checkLocation
  },
  {
    path:'/login',
    name: 'Login',
    component: Login
  },
  {
    path:'/ViewAStudent/:id',
    name: 'ViewAStudent',
    component: viewAStudent,
    props: true,
    beforeEnter: requireAuth

  },
  {
    path:'/Notes/:studentid',
    name: 'Notes',
    component: Notes,
    props: true,
    beforeEnter: requireAuth

  },
  {
    path:'/AddReport/:studentid',
    name: 'AddReport',
    component: AddReport,
    props: true,
    beforeEnter: requireAuth

  },
  {
    path:'/AddConsent/:studentid',
    name: 'AddConsent',
    component: AddConsent,
    props: true,
    beforeEnter: requireAuth

  },
  {
    path: '/NewReceipt',
    name: 'NewReceipt',
    component: NewReceipt, 
    beforeEnter: requireAuth
  },
  {
    path: '/NewTransfer',
    name: 'NewTransfer',
    component: NewTransfer, 
    beforeEnter: requireAuth
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
