<template>
      <span class="title">{{ note.Note }}</span>
      <br>
      <p class="grey-text">Added {{ timeAgo }}</p>
      <div class="secondary-content"></div>
      <br><a v-bind:href="note.Photo" target="_blank"><div v-if="img" class="btn">View Image</div></a>
      
</template>

<script>
import { ref } from '@vue/reactivity'
import { format, render, cancel, register } from 'timeago.js';

export default {
    props: ['note'],
    setup(props) {
        const date = ref()
        const img = ref(false)
        const timeAgo = ref()
        timeAgo.value = format(props.note.DateAdded.seconds * 1000)

        if(props.note.Photo === "No Photo"){
            
        }else{
            img.value = true
        }

        return {
            date,
            timeAgo, 
            img
            
        }

}
}
</script>

<style>

</style>