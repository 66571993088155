import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'



const getDocs = () => {
    const documents = ref([])
    const error = ref(null)

    const loadDocs = async(idDoc) => {
      try{

        projectFirestore.collection('Documents')
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          documents.value = docs
          var i = 0

         while (i < documents.value.length) {
              if(documents.value[i].childId === idDoc){
                
                 
              
              }else{
                documents.value.splice(i,1)
                i--
              }
              i++
          }
          i = 0
        })

      }
      catch(err){
        error.value = err.message    
        console.log(error.value)    
      }

    }
    return{ documents, error, loadDocs }
}

export default getDocs