import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'



const getReports = () => {
    const reports = ref([])
    const error = ref(null)

    const loadReport = async(idReport) => {
      try{

        projectFirestore.collection('Reports')
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          reports.value = docs
          var i = 0

         while (i < reports.value.length) {
           //console.log(reports.value.length)
           //console.log(idNote)
              if(reports.value[i].ChildID === idReport){
                
                 
              
              }else{
                reports.value.splice(i,1)
                i--
              }
              i++
          }
          i = 0
        })

      }
      catch(err){
        error.value = err.message    
        console.log(error.value)    
      }

    }
    return{ reports, error, loadReport }
}

export default getReports