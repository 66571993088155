<template>
<div v-if="!student.FirstName == ''">
<div class="container">
    <div v-if="student.PP" class="green white-text center pulse">Sponsored By Project Possible</div>
      <div class="row">
    <div class="col s12 m4 l4">
      <div class="card">
        <div class="card-image">
          <img v-bind:src="student.Photo">
        </div>
        <div  class="file-field input-field col s12">
            <div class="btn green" style="width:100%">
                <span><i class="tiny material-icons">add_a_photo</i></span>
                <input type="file"  @change="newPhoto" accept="image/*">
            </div>
            </div>
        <div class="card-content">      
            <span class="card-title">{{ student.FirstName }} {{ student.LastName }}</span>
            <div class="row">
                <div class="col s12">
                    <p class="chip blue-grey white-text center">{{ age }} Years Old</p>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <p class="chip blue-grey white-text center" style="">Started Sponsorship: {{ student.StartYear }}</p>
                </div>
            </div>
            <div class="row" v-if="student.Grade">
                <div class="col s12">
                    <p class="chip blue-grey white-text center" style="">Current Year: {{ student.Grade }}</p>
                </div>
            </div>
            <!--<div class="row">
                <div class="col s12">
                    <p v-if="student.Sponsor" class="chip green white-text center" >Sponsored</p>
                    <p v-else class="chip red white-text center">Not Sponsored</p>
                </div>
            </div>-->
            <div class="row">
                    <div class="col s12">
                    <p class="chip green white-text center" v-if="student.Permission == 'true'" style=""><a target="_blank" v-bind:href="student.Consent" class="white-text">Photo Permission</a></p>
                    <p v-else class="white-text chip red center" style="margin-left: 5px;">No Photo Permission</p>
            </div>
            <router-link :to="{name: 'AddReport', params: {studentid: id}}"><div class="btn" style="width: 100%; margin-top: 10px"> File New Report </div></router-link>
            <div  class="file-field input-field col s12">
            <div class="btn" style="width:100%">
                <span>Upload Consent</span>
                <input type="file"  @change="handleFile2" accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword">
            </div>
            </div>
           
            <div  class="col s12">
            <div class="btn" style="width:100%;" @click="changeGrade(id)">
                <span>Update Grade</span>
            </div>
            </div>
            <div class="col s12" style="height: 15px;"></div>
            <div v-if="student.Profile" class="col s12">
            <div class="btn red" style="width:100%;" @click="viewDocument(student.Profile)">
                <span>View Profile Document</span>
            </div>
            </div>
            <div v-if="!student.Profile" class="col s12">
            <div class="btn red" style="width:100%;" @click="generateDocument()">
                <span>Generate Profile Document</span>
            </div>
            </div>
            

                
            </div>
            
            
            
            

      
        </div>
      </div>
      <div class="card">
        <div class="card-content">      
            <span class="card-title center"><i class="material-icons medium">location_on</i></span>
            <h4 class="center flow-text"> {{ student.Address }}</h4>
       

        </div>
      </div>
      <div class="card">
          <div class="card-content">
              <div class="card-title center"><i class="material-icons">folder</i> Documents</div>
              <ul class="collection" >
                <div v-if="!docToggle" class="btn" @click="handleClickDoc" style="width:100%">Upload New Document</div>
                <div v-if="docToggle" class="btn red" @click="handleClickDoc" style="width:100%">Cancel</div>
    <li class="collection-item" v-for="document in documents" :key = document.id>
        <p align="center">
            <a style="text-align: centre" target="_blank" v-bind:href="document.Document">{{ document.DocumentTitle }} <span class="material-icons" style="font-size: 18px; text-align: right;">visibility</span></a>
        </p>
 
    </li>
    </ul>
    <div v-if="docToggle">
                  
              
                <div class="input-field col s12">
          <input id="docTitle" type="text" class="active" v-model="docTitle">
          <label class="active" for="docTitle">Document Description</label>
        </div>
                 <div class="file-field input-field col s12">
      <div class="btn blue-grey" style="width:100%;">File  
        <input type="file" @change="handleFileSecond" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps">
      </div>
    <div class="btn green" v-if="docMsg"  style="width:100%; margin-top:5px;">
          <i class="large material-icons left">check</i>Ready</div>
        </div>
                <div class="btn blue-grey" style="width:100%; margin-top:10px;" @click="saveDoc">Save</div>


          
      </div>
          </div>
      </div>
      <div class="card">
          <div class="card-content">
              <div class="card-title center"><i class="material-icons">border_color</i> Reports</div>
              <ul class="collection" >
            <a href="https://drive.google.com/drive/folders/1GcnxsLjjlCGCg-yObJxg7njz37BnRkHI"><div class="btn" style="width:100%">View All Reports</div></a>
      <li class="collection-item" v-for="report in reports" :key = report.id>
          <div class="btn" style="width:100%" @click='handleReportClick(report.id)'>{{ month(report.DateSaved)}}</div>
          <br>
          </li>
    </ul>
          </div>
      </div>
    </div>
    <div class="col s12 m8 l8">
    <div v-if="!edit">
      <div class="card">
          <div class="card-content">
              <a class="chip right waves-effect waves-light red white-text center" style="width:25%!important" @click='handleClickExit'>Cancel Edit</a>
              <div class="card-title">Edit Student Details</div>
              
                        

              <div class="card-body">
                      <form class="container" @submit.prevent>
      <div class="row">
        <div class="input-field col s6">
          <input id="first_name" type="text" class="active" v-model="fname">
          <label class="active" for="first_name">First Name</label>
        </div>
        <div class="input-field col s6">
          <input id="last_name" focus type="text" class="validate" v-model="lname">
          <label class="active" for="Last_name">Last Name</label>
        </div>
      </div>
      <div class="row">
          <div class="input-field col s12 m6">
          <input id="school" focus type="text" class="validate" v-model="school">
          <label class="active" for="school">School Name</label>
      </div>
        
        <div class="input-field col s12 m6">
          <input id="Address" focus type="text" class="validate" v-model="address">
          <label class="active" for="Address">Address</label>
        </div>
      </div>
      <div class="row">

      <div class="input-field col s12 m12">
          <input id="Background" focus type="text" class="validate" v-model="background">
          <label class="active" for="Background">Background</label>
        </div>
      </div>
      
      <div class="row">
      <div class="input-field col s12 m12">
          <input id="Reason" focus type="text" class="validate" v-model="reason">
          <label class="active" for="Reason">Reason</label>
        </div>
      </div>

      <div class="btn blue-grey" style="width:100%" @click="handleEdit">Save Changes</div>

  
    </form>

              </div>
          </div>
      </div>
    </div>
    <div v-if="edit">
      <div class="card">
          <div class="card-content">
              <a class="chip right waves-effect waves-light green white-text center" style="width:25%!important" @click='handleClickExit'>Edit Details</a>

              <div class="card-title">Student Details</div>
              <div class="card-body">
                  <div class="row">
                      <div class="col s3">
                          Background:
                      </div>
                      <div class="col s9">
                          {{ student.Background }}
                      </div>
                  </div>

                  <div class="divider"></div>

                    <div class="row">
                      <div class="col s3">
                          Boarding Status:
                      </div>
                      <div class="col s9">
                          {{ student.BoardingStatus }}
                      </div>
                  </div>

                  <div class="divider"></div>

                  <div class="row">
                      <div class="col s3">
                          What is that student looking forward to?
                      </div>
                      <div class="col s9">
                          {{ student.LookingForward }}
                      </div>
                  </div>

                  <div class="divider"></div>

                    <div v-if="!student.Ortho" class="row">
                      <div class="col s3">
                          Ortho Procedure Details
                      </div>
                      <div class="col s9">
                          {{ student.OrthoDetails }}
                      </div>
                  </div>

                  <div class="divider"></div>

                  <div class="row">
                      <div class="col s3">
                          School
                      </div>
                      <div class="col s9">
                          {{ student.School }}
                      </div>
                  </div>

                  <div class="divider"></div>

                  <div class="row">
                      <div class="col s3">
                          Reason for starting in this year
                      </div>
                      <div class="col s9">
                          {{ student.YearReason }}
                      </div>
                  </div>

                  <div class="divider"></div>

              </div>
          </div>
      </div>
    </div>
          <div class="card">
          <div class="card-content">
              <a v-if="!noteToggle" class="chip right waves-effect waves-light green white-text center" style="width:25%!important" @click='handleClickNote'>Add New Note</a>
              <a v-else class="chip right waves-effect waves-light red white-text center" style="width:25%!important" @click='handleClickNote'>Cancel Note</a>

              <div class="card-title">Student Notes</div>

              <div class="card-body">
                                   
                  <div v-if="noteToggle">
                  <div class="card">
          <div class="card-content">
              
              <div class="card-title" style="font-size: 18px;">Add New Note</div>
              <div class="card-body">
                  <div class="input-field s12">
                    <textarea id="note" class="materialize-textarea" v-model="note"></textarea>
                    <label for="note" v-bind:class="active">Note</label>
                 </div>
                 <div class="file-field input-field col s12">
      <div class="btn blue-grey" style="width:100%">
        <span>Upload Photo</span>  
        <input type="file" @change="handleFile" accept="image/*">
      </div>
    <div class="btn green" v-if="imgMsg"  style="width:100%; margin-top:5px;">
          <i class="large material-icons left">check</i>Photo ready to upload
        
      </div>
        </div>
                <div class="btn blue-grey" style="width:100%; margin-top:10px;" @click="saveDoc">Save Document</div>


              </div>
          </div>
          </div>
      </div>
        <div class="row">
    <div class="col m1 l1"></div>
    <div class="col s12 m10 l10">
    <ul class="collection" >
      <li class="collection-item" v-for="note in notes" :key = note.id>
          <span class="title">{{ note.Note }}</span>
          <br>
          <hr style="margin-top:20px; border-top: 2px solid black;">
          <p class="content grey-text"><i class="tiny material-icons">access_time</i> {{ timeAgoFunc(note.DateAdded) }}<br> <i class="tiny material-icons">create</i>  {{note.AddedBy}}</p>
      <div class="secondary-content"></div><a v-bind:href="note.Photo" target="_blank"><div v-if="getPhoto(note.Photo)" class="btn">View Image</div></a>
      </li>
    </ul>
    </div>
  </div>


              </div>
          </div>
      </div>
    </div>
  </div>
</div>
</div>

            <div v-else>
            <Spinner />
            </div>

 
</template>

<script>
import Spinner from '../components/Spinner.vue'
import getStudent from '../Composables/getStudent'
const Swal = require('sweetalert2')
import {useRouter} from 'vue-router'
import { format, render, cancel, register } from 'timeago.js';
import { ref } from '@vue/reactivity'
import Notes from '../components/Notes.vue'
import { onBeforeMount, onMounted, watch } from '@vue/runtime-core'
import { projectAuth, projectFirestore, timestamp } from '../firebase/config'
import onUpload from '../Composables/uploadImage'
import NoteList from '../components/NoteList.vue'
import getNotes from '../Composables/getNotes'
import ReportList from '../components/ReportList.vue'
import getReports from '../Composables/getReports'
import getDocs from '../Composables/getDocs'






 $(document).ready(function() {
    M.updateTextFields();
  });
      







export default {
    props: ['id'],
    components: {Spinner, NoteList, Notes},
    setup(props){

        const consentImg = ref()
        var imageCons
        var ConsUpload

        const docImg = ref()
        var imageDoc
        var docUpload

        const handleDocumentUpload = async (e) =>{
            docImg.value = e.target.files[0]

            Swal.fire({
                    title: 'Uploading Document',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                })
                Swal.showLoading()

            if(docImg.value == null){
                Swal.close()
                Swal.fire({
                    title: 'Error!',
                    text: 'Document is invalid',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                docUpload = null
            }else{
                imageDoc = await onUpload(docImg.value)
                docUpload = imageDoc.value
            }
            Swal.close()
            if(docUpload){
                const DocUploadData = {Student: props.id, Description: description, Document: docUpload}

                try{
                const response = await projectFirestore.collection('Documents').add(DocUploadData)

                Swal.fire({
                    title: 'Success!',
                    text: 'Document Uploaded.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                edit.value = true

            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }
            }
        }
    
          

        const handleFile2 = async (e) =>{
            consentImg.value = e.target.files[0]

            Swal.fire({
                    title: 'Uploading Consent File',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                })
                Swal.showLoading()

            if(consentImg.value == null){
                Swal.close()
                Swal.fire({
                    title: 'Error!',
                    text: 'Consent form is invalid',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                ConsUpload = null
            }else{
                imageCons = await onUpload(consentImg.value)
                ConsUpload = imageCons.value
                console.log(ConsUpload)
            }
            Swal.close()
            if(ConsUpload){
                const UploadData = {Permission: 'true', Consent: ConsUpload}

                try{
                const response = await projectFirestore.collection('Students').doc(props.id).update(UploadData)

                Swal.fire({
                    title: 'Success!',
                    text: 'Consent Recorded.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                edit.value = true

            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }
            }
    
            
        }
        const newImg = ref()
        var NewUpload
        var imageNew




        const getPhoto = (note) => {
            if(note == "No Photo"){
                return false
            }else{
                return true
            }
        }

        const timeAgoFunc = (note) => {
            var time = format(note.seconds * 1000)
            return time
        }

        const month = (report) => {
            var time = format(report.seconds*1000)
            return time
        }

        const changeGrade = async (id) => {

            const { value: yearSelect } = await Swal.fire({
  title: 'Select A Grade',
  input: 'select',
  inputOptions: {
    'Primary': {
      PP1: 'PP1',
      PP2: 'PP2',
      GradeZ1: 'Grade 1',
      GradeZ2: 'Grade 2',
      GradeZ3: 'Grade 3',
      GradeZ4: 'Grade 4',
      GradeZ5: 'Grade 5',
      GradeZ6: 'Grade 6',
      GradeZ7: 'Grade 7',
      GradeZ8: 'Grade 8',
    },
    'Secondary': {
      FormZ1: 'Form 1',
      FormZ2: 'Form 2',
      FormZ3: 'Form 3',
      FormZ4: 'Form 4',
    },
    'Higher': {
        HigherZEducationZYearZ1: 'Higher Year 1',
        HigherZEducationZYearZ2: 'Higher Year 2',
        HigherZEducationZYearZ3: 'Higher Year 3',
        HigherZEducationZYearZ4: 'Higher Year 4',
        HigherZEducationZYearZ5: 'Higher Year 5',
    }
  },
  inputPlaceholder: 'Select a Year',
  showCancelButton: true,
})

let yearSelectNew = yearSelect.replace("Z", " ")
let yearSelectNewNew = yearSelectNew.replace("Z", " ")
let yearSelectNewNewNew = yearSelectNewNew.replace("Z", " ")
console.log(yearSelectNewNewNew)
try{
                const response2 = await projectFirestore.collection('Students').doc(id).update({Grade: yearSelectNewNewNew})

                Swal.fire({
                    title: 'Success!',
                    text: 'New year has been saved.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                


            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }


        }

                const generateDocument = async () => {
                console.log(props.id)
                fetch('https://hook.eu1.make.com/v5env2a7lyvymb2orjg60wbdy3f1imox?id='+props.id).then(()=>{
                    let timerInterval
Swal.fire({
  title: 'Report is being generated and saved in Google Drive',
  html: '<b></b> seconds remaining, please do not close this window.',
  timer: 60000,
  timerProgressBar: true,
  didOpen: () => {
    Swal.showLoading()
    const b = Swal.getHtmlContainer().querySelector('b')
    timerInterval = setInterval(() => {
      b.textContent = Math.round(Swal.getTimerLeft()/1000)
    }, 100)
  },
  willClose: () => {
    clearInterval(timerInterval)
  }
}).then((result) => {
  /* Read more about handling dismissals below */
  
}).then(()=>{
                    document.location.reload(true)
                })

                })
                
                

                
            



        }

        const handleReportClick = async (report) => {
            const response1 = await projectFirestore.collection('Reports').doc(report).get()
            console.log(response1.data().ProForma)

            if(response1.data().ProForma){
                //Form exists
                window.open(response1.data().ProForma)
        
            }else{
                fetch('https://hook.eu1.make.com/u8753mosqc6ysxgacascq0qxndp6v9hq?id='+report).then(()=>{
                    let timerInterval
Swal.fire({
  title: 'Document is being saved in Google Drive',
  html: '<b></b> seconds remaining, please do not close this window.',
  timer: 60000,
  timerProgressBar: true,
  didOpen: () => {
    Swal.showLoading()
    const b = Swal.getHtmlContainer().querySelector('b')
    timerInterval = setInterval(() => {
      b.textContent = Math.round(Swal.getTimerLeft()/1000)
    }, 100)
  },
  willClose: () => {
    clearInterval(timerInterval)
  }
}).then((result) => {
  /* Read more about handling dismissals below */
  
}).then(()=>{
                    document.location.reload(true)
                })

                })
                
                

                
            }



        }

        



        const componentKey = ref(0)
 
        const edit = ref(true)
        const router = useRouter()
        const dob = ref()
        const age = ref()
        const timeAgo = ref()
        const fname = ref()
        const lname = ref()
        const dob2 = ref()
        const address = ref()
        const boarder = ref()
        const school = ref()
        const noteToggle = ref(false)
        const docToggle = ref(false)
        const imgMsg = ref(false)
        const docMsg = ref(false)
        const image = ref()
        const docRaw = ref()
        const note = ref()
        const docTitle = ref()
        const ogPhoto = ref()
        const background = ref()
        const reason = ref()
        var upload
        var img
        const active = ref()
        const id = props.id
        const showAllNotes = ref(true)




        

        const {student, error, load, studentId} = getStudent(props.id)
        load().then(() => {
            console.log(error.value)
            if(error.value){
            Swal.fire({
                    title: 'Error!',
                    text: 'Student record does not exist',
                    icon: 'question',
                    confirmButtonText: 'Close'
                }).then(() => {
                    router.push({name: 'ViewAllStudents'})
                })
                
            }
        const dobDate = new Date(student.value.DOB.seconds * 1000)
        const year = dobDate.getFullYear()
        const yearNow = new Date().getFullYear()
        age.value = yearNow - year
        address.value = student.value.Address
        fname.value = student.value.FirstName
        lname.value = student.value.LastName
        dob2.value = student.value.DOB
        school.value = student.value.School
        background.value = student.value.Background
        reason.value = student.value.YearReason


        })
         const newPhoto = async (e) =>{
            //console.log("*********"+student.value.Photo)
            newImg.value = e.target.files[0]

            Swal.fire({
                    title: 'Uploading New Photo',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                })
                Swal.showLoading()

            if(newImg.value == null){
                Swal.close()
                Swal.fire({
                    title: 'Error!',
                    text: 'Error uploading new photo',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                NewUpload = null
            }else{
                imageNew = await onUpload(newImg.value)
                NewUpload = imageNew.value
               
            }
            Swal.close()
            if(NewUpload){
                const ChangeData = {Photo: NewUpload}
                const NoteDataNew = {childId: props.id,
                Note: 'Main Photo Changed, view old photo below.',
                DateAdded: timestamp(),
                Photo: student.value.Photo,
                AddedBy: projectAuth.currentUser.email}

                try{
                const response1 = await projectFirestore.collection('Students').doc(props.id).update(ChangeData)
                const response12 = await projectFirestore.collection('Notes').add(NoteDataNew)

                Swal.fire({
                    title: 'Success!',
                    text: 'Photo Updated.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })
            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }
            }
    
            
        }

        const {notes, errorNote, loadNote} = getNotes()
        loadNote(id)

        const {reports, error1, loadReport} = getReports()
        loadReport(id)

        const {documents, error2, loadDocs} = getDocs()
        loadDocs(id)

        const handleClick = () => {
            if(edit.value){
            edit.value = false
            }else{
                edit.value = true
            }

        }

        const viewDocument = (link) => {
            window.open(link);

        }

        const handleClickExit = () => {
            if(edit.value){
            edit.value = false
            }else{
                edit.value = true
            }

        }

        const handleClickNote = () => {
            if(noteToggle.value){
            noteToggle.value = false
            showAllNotes.value = true
            }else{
                noteToggle.value = true
                showAllNotes.value = false
            if (note.value == null || note.value == ""){
            active.value = ""

        }else{
            active.value="active"
        }
            }

        }

        const handleClickDoc = () => {
            if(docToggle.value){
            docToggle.value = false
            }else{
                docToggle.value = true
            }

        }

        const handleEdit = async () => {
            if(fname.value == "" || lname.value == "" || address.value == "" || school.value == "" || background.value == "" || reason.value == ""){
                Swal.fire({
                    title: 'Error!',
                    text: 'There is some data missing, please complete all fields and try again.',
                    icon: 'question',
                    confirmButtonText: 'Close'
                })

                


            }else{

                const childData = {FirstName: fname.value, LastName: lname.value, Address: address.value, School: school.value, Background: background.value, YearReason: reason.value}

                try{
                const response = await projectFirestore.collection('Students').doc(props.id).update(childData)

                Swal.fire({
                    title: 'Success!',
                    text: 'Child data has been updated.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                edit.value = true

            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }
            }

        }
        const handleFile = (e) => {
            image.value = e.target.files[0]
            imgMsg.value = true
        }

        const handleFileSecond = (e) => {
            docRaw.value = e.target.files[0]
            docMsg.value = true
        }


        const saveNote = async () => {


            if(note.value == null){
                Swal.fire({
                    title: 'Error!',
                    text: "There is no note data, please enter a note.",
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else{

            Swal.fire({
                    title: 'Uploading Images',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                })
                Swal.showLoading()

            if(image.value == null){
                upload = "No Photo"
            }else{
                img = await onUpload(image.value)
                upload = img.value
            }
            Swal.close()            
            
            const noteData = {
                childId: props.id,
                Note: note.value,
                DateAdded: timestamp(),
                Photo: upload,
                AddedBy: projectAuth.currentUser.email
            }
            
            try{
                const response = await projectFirestore.collection('Notes').add(noteData)

                Swal.fire({
                    title: 'Success!',
                    text: 'Note has been saved.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                note.value = ""
                img = ""
                active.value = null
                imgMsg.value= false
                //componentKey.value = componentKey.value + 1
                noteToggle.value = false
                showAllNotes.value = true

                //router.push({name: 'Home'})


            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }

            
                
           
               
            

            }
        
        }


const saveDoc = async () => {


if(docTitle.value == null){
    Swal.fire({
        title: 'Error!',
        text: "Please enter the title of this document.",
        icon: 'error',
        confirmButtonText: 'Close'
    })
}else{

Swal.fire({
        title: 'Uploading Document',
        allowEscapeKey: false,
        allowOutsideClick: false,
    })
    Swal.showLoading()


    img = await onUpload(docRaw.value)
    upload = img.value
Swal.close()            

const docData = {
    childId: props.id,
    DocumentTitle: docTitle.value,
    DateAdded: timestamp(),
    Document: upload,
    AddedBy: projectAuth.currentUser.email
}

try{
    const response = await projectFirestore.collection('Documents').add(docData)

    Swal.fire({
        title: 'Success!',
        text: 'Document has been saved.',
        icon: 'success',
        confirmButtonText: 'Close'
    })

    docTitle.value = ""
    img = ""
    active.value = null
    docMsg.value= false
    //componentKey.value = componentKey.value + 1
    docToggle.value = false

    //router.push({name: 'Home'})


}
catch(err){

     Swal.fire({
        title: 'Error!',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'Close'
    })
    
}


    

   


}

}
 
        

        return {
            saveDoc, handleFileSecond, docTitle, handleClickDoc, docToggle, generateDocument, viewDocument, changeGrade, student, error, age, handleClick, edit, fname, lname, dob2, address, boarder, school, handleClickExit,handleEdit, noteToggle, handleClickNote, saveNote, imgMsg, handleFile, note, active,id,componentKey, showAllNotes, notes, getPhoto, timeAgoFunc, handleFile2, newPhoto, reports, loadReport, month, handleReportClick, background, reason, documents, loadDocs, docMsg, documents

        }

    }
}
</script>

<style scoped>
.chip{
    width: 100%;
}

</style>