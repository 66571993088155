import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'



const getStudents = () => {
    const students = ref([])
    const error = ref(null)
    const resultSearch = ref([])

    const load = async() => {
      try{

        const response = await projectFirestore.collection('Students').where('Archive', '==', false)
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          students.value = docs
          resultSearch.value = docs
        })

        //cycle through and extract data

        //students.value = response.docs.map(
        //  doc => {
        //    return { ...doc.data(), id: doc.id }
        //  }
        //)

      }
      catch(err){
        error.value = err.message    
        console.log(error.value)    
      }

    }
    return{ students, resultSearch, error, load }
}

export default getStudents