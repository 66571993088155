import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'



const getDecStudents = () => {
    const students = ref([])
    const error = ref(null)
    const resultSearch = ref([])
    const decKids = ref([])

    const load = async() => {
      try{

        const response = await projectFirestore.collection('Students').where('Archive', '==', false).orderBy('DateAdded', "desc")
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          students.value = docs
          

          decKids.value = students.value.filter(student => student.School == "DEC")
          resultSearch.value = decKids.value
        })


      }
      catch(err){
        error.value = err.message    
        console.log(error.value)    
      }

    }
    return{ decKids, resultSearch, error, load }
}

export default getDecStudents