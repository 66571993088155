import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'



const getArchiveStudents = () => {
    const students = ref([])
    const error4 = ref(null)
    const resultSearch4 = ref([])
    const archiveStudents = ref([])

    const load4 = async() => {
      try{

        const response = await projectFirestore.collection('Students').where('Archive', '!=', false)
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          students.value = docs

          archiveStudents.value = students.value

          resultSearch4.value = archiveStudents.value
        })


      }
      catch(err){
        error4.value = err.message    
        console.log(error4.value)    
      }

    }
    return{ archiveStudents, resultSearch4, error4, load4 }
}

export default getArchiveStudents