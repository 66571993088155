import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'



const getHighStudents = () => {
    const students = ref([])
    const error3 = ref(null)
    const resultSearch3 = ref([])
    const highStudents = ref([])

    const load3 = async() => {
      try{

        const response = await projectFirestore.collection('Students').where('Archive', '==', false).orderBy("DateAdded", "desc")
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          students.value = docs
          

          highStudents.value = students.value.filter(student => student.School != "DEC")
          highStudents.value = highStudents.value.filter(student => student.StartYear != "Form 1")
          highStudents.value = highStudents.value.filter(student => student.StartYear != "Form 2")
          highStudents.value = highStudents.value.filter(student => student.StartYear != "Form 3")
          highStudents.value = highStudents.value.filter(student => student.StartYear != "Form 4")
          resultSearch3.value = highStudents.value
        })


      }
      catch(err){
        error3.value = err.message    
        console.log(error3.value)    
      }

    }
    return{ highStudents, resultSearch3, error3, load3 }
}

export default getHighStudents