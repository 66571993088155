<template>
      <!--<img v-bind:src="pic" alt="" class="circle">-->
      <span class="title">{{ student.FirstName }} {{ student.LastName }}</span>
      <p>Age: {{ age }} <br></p>
      <div class="chip">{{ student.School }}</div>
      <div v-if="!student.Grade" class="chip">{{ student.StartYear }}</div>
      <div v-else class="chip">{{ student.Grade }}</div>

      <div v-if="student.PP" class="chip orange">PP Sponsored</div>
      <br>
      <div class="row">
      <!--<div v-if="student.Sponsor" class="chip green col" ><p class="white-text">Sponsor</p></div>
      <div v-else class="chip red col" ><p class="white-text">No Sponsor</p></div>-->
      <div v-if="student.Permission == 'true'"><a target="_blank" v-bind:href="student.Consent"><div class="col chip green"><p class="white-text">Photo Permission</p></div></a></div>
      <div v-else><div class="chip red col"><p class="white-text">No Photo Permission</p></div></div>
      </div>
      
      <div class="row"><div  v-if="!student.Archive" class="chip blue white-text" @click="archiveStudent(student.id)" >Move to Archive</div><div v-else class="chip light-blue white-text" @click="revokeStudent(student.id)" >Return to List</div></div>
      <div class="row hide-on-med-and-up">
          <div class="col s6 m3 l3">
              <router-link :to="{name: 'ViewAStudent', params: {id: student.id}}"><div class="btn" style="width:100%;">Details</div></router-link>

          </div>
          <div class="col s6 m3 l3">
              <router-link v-if="report" :to="{name: 'AddReport', params: {studentid: student.id}}"><div  class="btn red" style="width:100%; margin-bottom: 5px;">Report Due</div></router-link>
              <router-link v-else :to="{name: 'AddReport', params: {studentid: student.id}}"><div  class="btn" style="width:100%; margin-bottom: 5px;">New Report</div></router-link>

              
          </div>
      </div>
      <div class="secondary-content">
        <p class="grey-text">Added {{ timeAgo }}</p>
        <br>
        <router-link :to="{name: 'ViewAStudent', params: {id: student.id}}"><div class="btn hide-on-small-only" style="width:100%; margin-bottom:5px;">Details</div></router-link>
        <br>
        <router-link v-if="report" :to="{name: 'AddReport', params: {studentid: student.id}}"><div  class="btn hide-on-small-only red pulse" style="width:100%;">Report Due</div></router-link>
        <router-link v-else :to="{name: 'AddReport', params: {studentid: student.id}}"><div  class="btn hide-on-small-only" style="width:100%;">New Report</div></router-link>
        <br>
        <div class="btn" style="width: 100%; margin-top:5px;" @click="changeGrade(student.id)">Update Grade</div>
        </div>
        

      
</template>

<script>
import { ref } from '@vue/reactivity'
import { format, render, cancel, register } from 'timeago.js';
import { projectFirestore } from '../firebase/config'
import { projectAuth } from '../firebase/config'
const Swal = require('sweetalert2')



export default {
    props: ['student'],
    setup(props) {
        const dob = ref()
        const age = ref()
        const dobDate = new Date(props.student.DOB.seconds * 1000)
        const year = dobDate.getFullYear()
        const yearNow = new Date().getFullYear()
        age.value = yearNow - year
        //console.log(props.student.Photo)
        const timeAgo = ref()
        timeAgo.value = format(props.student.DateAdded.seconds * 1000)
        const added = ref()
        added.value = props.student.DateAdded.seconds
        var currentDateTime = new Date()
        var SixMonthsAgo = currentDateTime - 15780000000
        const report = ref(false)
        const pic = ref()
        const src = ref()

                const changeGrade = async (id) => {

            const { value: yearSelect } = await Swal.fire({
  title: 'Select A Grade',
  input: 'select',
  inputOptions: {
    'Primary': {
      PP1: 'PP1',
      PP2: 'PP2',
      GradeZ1: 'Grade 1',
      GradeZ2: 'Grade 2',
      GradeZ3: 'Grade 3',
      GradeZ4: 'Grade 4',
      GradeZ5: 'Grade 5',
      GradeZ6: 'Grade 6',
      GradeZ7: 'Grade 7',
      GradeZ8: 'Grade 8',
    },
    'Secondary': {
      FormZ1: 'Form 1',
      FormZ2: 'Form 2',
      FormZ3: 'Form 3',
      FormZ4: 'Form 4',
    },
    'Higher': {
        HigherZEducationZYearZ1: 'Higher Year 1',
        HigherZEducationZYearZ2: 'Higher Year 2',
        HigherZEducationZYearZ3: 'Higher Year 3',
        HigherZEducationZYearZ4: 'Higher Year 4',
        HigherZEducationZYearZ5: 'Higher Year 5',
    }
  },
  inputPlaceholder: 'Select a Year',
  showCancelButton: true,
})

let yearSelectNew = yearSelect.replace("Z", " ")
let yearSelectNewNew = yearSelectNew.replace("Z", " ")
let yearSelectNewNewNew = yearSelectNewNew.replace("Z", " ")
console.log(yearSelectNewNewNew)
try{
                const response2 = await projectFirestore.collection('Students').doc(id).update({Grade: yearSelectNewNewNew})

                Swal.fire({
                    title: 'Success!',
                    text: 'New year has been saved.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                


            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }


        }

        const archiveStudent = async (id) => {


            if(projectAuth.currentUser.email == "aiden.lugg@icfem-mission.org"){
            const archive = await projectFirestore.collection('Students').doc(id).update({'Archive': true});
            }

        }

        const revokeStudent = async (id) => {
        
            if(projectAuth.currentUser.email == "aiden.lugg@icfem-mission.org"){
            const archive = await projectFirestore.collection('Students').doc(id).update({'Archive': false});
            }

        }
        
        src.value = "https://hook.integromat.com/jmmwykt7tvfh5otll8vjjocg4y9qikb2?id=" + props.student.id
        //console.log(src)

        //if(props.student.Photo === "No Photo"){
        //    pic.value = "https://firebasestorage.googleapis.com/v0/b/child-sponsorship-1d6ef.appspot.com/o/402-4022635_avatar-generic-person-icon.png?alt=media&token=64a91e06-83bf-4b7a-ae12-f7b1eb14f17f"
        //}else{
        //    pic.value = props.student.Photo
       // }

        const ReportDue = async (time, id) => {
            var timeLog = time.seconds *1000

            if(SixMonthsAgo >= timeLog){   
                var response = await projectFirestore.collection('Reports').where("ChildID", "==", id).get()
                 if(response.docs.length == 0){
                     
                     return true
                 }else{
                     return false
                 }
                }else{
                return false
            }

        }

        ReportDue(props.student.DateAdded, props.student.id).then((val)=>{
            report.value = val
            //console.log(val + props.student.id)
        }

        )
        
        
  
        


        return {
            dob,
            age,
            timeAgo,
            ReportDue, 
            report,
            pic,src,
            archiveStudent,
            revokeStudent,
            changeGrade
            
        }

}
}
</script>

<style>

</style>