<template>
  <div class="container">    
      <div class="container" style="padding-top: 40px; padding-bottom: 40px;">
       <h4 class="center">Plant a Tree</h4>
       
      <form @submit.prevent>
        <div class="row">
            <div class="col m3"></div>
            <div class="input-field col s12 m6">
        <select v-model="tree" >
            <option value="Guava">Guava</option>
            <option value="Oak">Oak</option>    
            <option value="Mango">Mango</option>
            <option value="Apple">Apple</option>
        </select>
        <label>Tree Type</label>
        </div>
        </div>
        <div class="row">
        <div class="col m3"></div>
        <div class="col s12 m6">
        <div class="waves-effect waves-light btn" style="width:100%" @click="GetLocation">Plant Tree</div>
        </div>
        </div>


      </form>

      </div>
       <GMapMap :key="mapRender"
      :center="center"
      :zoom="7"
      map-type-id="terrain"
    style="width: 500px; height: 300px"
  >
      <GMapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          @click="center=m.position"
      />
  </GMapMap>
  </div>
     
</template>



<script>

import { ref } from '@vue/reactivity'
import useSignup from '../../Composables/SignUp'
import {useRouter} from 'vue-router'
import useLogin from '../../Composables/useLogin'
import { onBeforeMount, onMounted, watch } from '@vue/runtime-core'
import {GetPlaceName} from '../../Composables/getPlaceName'
import { projectAuth, projectFirestore, timestamp } from '../../firebase/config'
import VueGoogleMaps from '@fawmi/vue-google-maps'





const Swal = require('sweetalert2')



export default {

    setup(props){

            onMounted(() => {
        //init Materialize elements
        var elems = document.querySelectorAll('select')
        var instances = M.FormSelect.init(elems, {})

        
    })


        const{error, login} = useLogin()

        const name = ref('')
        const email1 = ref('')
        const password1 = ref('')
        const router = useRouter()
        const lat = ref()
        const long = ref()
        const tree = ref()
        const center = ref({lat: 51.093048, lng: 6.842120})
        const mapRender = ref(0)
        //const markers = [{position: {lat: 51.093048, lng: 6.842120}}]
        const markers = ref([])

const getTrees = async() => {
    const trees = ([])
    var i = 0
    var latLng = []
      try{

        const response = await projectFirestore.collection('Trees')
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          trees.value = docs
          console.log(trees.value.length)

          while(i < trees.value.length){
              latLng.push({position: {lat: trees.value[i].lat, lng: trees.value[i].long}})
              i++
          }

          markers.value = latLng
          mapRender.value = mapRender + 1
          console.log(markers)

        })

      }
      catch(err){
        error.value = err.message    
        console.log(error.value)    
      }

    }


        getTrees().then((result) => console.log(result))
        const GetLocation = async() => {
        Swal.fire({
                    title: 'Getting Location',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                })
                Swal.showLoading()
        navigator.geolocation.getCurrentPosition(
            position => {
                //console.log(position.coords.latitude);
                //console.log(position.coords.longitude);
                lat.value = position.coords.latitude
                long.value = position.coords.longitude
                center.value = {lat: lat.value,lng: long.value}
                GetPlaceName(lat.value,long.value).then(
                (result) => {
                console.log(result)
                const treeData = {
                Type: tree.value,
                lat: lat.value,
                long: long.value,
                PlaceName: result
                }

                mapRender.value = mapRender.value + 1

                try{
                projectFirestore.collection('Trees').add(treeData).then(() => getTrees().then((result) => console.log(result)))

                Swal.fire({
                    title: 'Success!',
                    text: 'Tree has been saved.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }
                })
                Swal.close()
                getTrees().then((result) => console.log(result))

                 

                

            
                
            error => {
                console.log(error.message);
                lat.value = error.message
                
            }  
            }         
        )
            
        

        }



    
        
        

        return {tree, GetLocation, lat, long, center, markers, mapRender}
    }
}


</script>

<style>

.vue-map-container{
    height: 400px;
    margin-bottom: 50px;
}

</style>
