<template>
<div class="container">
<h4 class="center">Link Child to Sponsor</h4>
   <div class="row">
       <div class="col s12 m4 l4">
      <div class="row">
          <div v-if="!students.length">
          <Spinner />
          </div>
        <div class="input-field col s12">
    <select id="students" @change = "handleSelect" v-model="studentSelect">
      
    </select>
    <label>Child Name</label>
  </div> 
  </div>
    </div>
    <div class="col s12 m4 l4 center" style="margin-top:25px">
        <i class="medium material-icons">arrow_forward</i>
          
    </div>
    <div class="col s12 m4 l4">
      <div class="row">
        <div class="input-field col s12">
          <i class="material-icons prefix">textsms</i>
          <input type="text" @input="handleInput" id="autocomplete-input" class="autocomplete">
          <label for="autocomplete-input">Donor Name - Begin Typing</label>
        </div>
        <div class="center">
        <button class="btn wave green" @click = "updateRecord">Save Link</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
const Swal = require('sweetalert2')
import { ref } from '@vue/reactivity'
import SelectList from '../components/studentSelectList.vue'
import getStudents from '../Composables/getStudents'
import Spinner from '../components/Spinner.vue'
import { onBeforeMount } from '@vue/runtime-core'
import { projectFirestore } from '../firebase/config'

const postNote = async (student, sponsor) => {
    const name = student
    const nameCleaned = name.replace(/\s/g, '%20')
    const response2 = await fetch('https://icfem-mission.org/hubspot_post.php?donor='+sponsor+'&student='+nameCleaned)
    const jSon = await response2.json()
    return jSon
}








export default {
    components: { SelectList, Spinner },
    setup() {
    const students = ref([])
    const error = ref(null)
    const studentSelect = ref()

    const load = async() => {

        var stus = []
      try{

        const response = await projectFirestore.collection('Students')
        .get()

        //cycle through and extract data

        stus = response.docs.map(
          doc => {
            return { ...doc.data(), id: doc.id }
          }
        )

      }
      catch(err){
        error.value = err.message    
      }
      return stus

    }

    load().then(function(result){
        students.value = result
        var options = "<option disabled selected>Select Student</option>"
        for (let i = 0; i < students.value.length; i++){
            options = options + "<option value='"+students.value[i].id+"|"+students.value[i].FirstName+" "+students.value[i].LastName+"'>"+students.value[i].FirstName+" "+students.value[i].LastName+"</option>" 

        }



        $(document).ready(function(){
        $('select').append(options)
        $('select').formSelect();

        });})

        const donor = ref()
        const handleInput = (e) => {
            donor.value = e.target.value
        }
        const sponsors = ref([])
        
        const getStuff = async () => { 
            var dataDump = {}   
            const response = await fetch('https://www.icfem-mission.org/hubspot.php')
            const jSon = await response.json()
            const test = jSon.toString()
            const test2 = JSON.stringify(test)
            const test3 = JSON.parse(test2)

            const count = Object.keys(jSon).length
           
            
            for (let i = 0; i < count; i++){
               const name = jSon[i]["fname"] + " " + jSon[i]["lname"] + " | " + jSon[i]["vid"]

               dataDump[name] = ""

                //dataDump[name] = jSon[i]["vid"]
            }

            return dataDump
        }
        var dataDump2 = {}
        const dataDump = getStuff().then(function(result) {
            dataDump2 = result
            $(document).ready(function(){
        $('input.autocomplete').autocomplete({
          data: dataDump2, 
          onAutocomplete: function(val){
            donor.value = val
        }
        }
        );
     
})
        }


        )

        const handleChange = () =>{
        }

        const updateRecord = async () => {
            const studentString = studentSelect.value
            const studentId = studentString.split("|")

            const donorString = donor.value
            const donorSplit = donorString.split("| ")
            
            projectFirestore.collection('Students')
            .doc(studentId[0])
            .update({Sponsor: donorSplit[0]})
            .then(() => {
                postNote(studentId[1], donorSplit[1]).then((result) => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Child data has been saved',
                    icon: 'success',
                    confirmButtonText: 'Close'
                }).then(()=> {
                    
                    if(result[0] == "no error"){
                    Swal.fire({
                    title: 'Success!',
                    text: 'Hubspot has been updated',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                }else{

                     Swal.fire({
                    title: 'Error!',
                    text: 'There was an error updating Hubspot, please ensure this is done manually',
                    icon: 'question',
                    confirmButtonText: 'Close'
                })

                }})

                
            })     
            })
            
            studentSelect.value = ""
            donor.value = ""


        }





        
    
        

        return{
            donor, handleInput, students, error, studentSelect, handleChange,updateRecord

        }

    }
}
</script>

<style>

</style>