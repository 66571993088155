<template>
<div class="container">
   <div class="row">
    <form class="col s12" @submit.prevent>
      <div class="row">
          <h5 class="center">New Consent for {{fname}} {{lname}}</h5>
        <div class="input-field col s6">
          <input disabled id="last_name" type="text" v-model="id">
          <label class="active" for="last_name">Child ID</label>
        </div>
        <div class="input-field col s12 m6">
          <input id = "date" type="text" class="datepicker" v-model.lazy="date">
          <label for="date">Today's Date</label>
        </div>
      </div>
      <div class="row">
      <div class="col s12 m6" id="p">
          I agree for photos of me/my child to be used in the following instances:
          <li>For observation of clinical progress</li>
          <li>For fundraising and increasing awareness of IcFEM Dreamland Mission including social media sites such as Facebook</li>
          <li>For educational purposes</li>

          Please enter the parent or Guardian name, their relationship to the child and the record of their signature.
      </div>

        <div class="input-field col s12 m6">
        <select v-model="ortho" @change = "handleChangeOrtho">
            <option value=false>I do not agree</option>
            <option value=true>I agree</option>    
        </select>
        <label>Do you agree?</label>
        </div>

        <div v-if="ortho">
        <div class="input-field col s6">
          <input id="last_name" type="text" v-model="name">
          <label for="last_name">Parent/Guardian Name</label>
        </div>
        <div class="input-field col s6">
          <input id="relate" type="text" v-model="relate">
          <label for="last_name">Relationship to {{fname}} {{lname}}</label>
        </div>
        </div>
        </div>



    </form>
    <div class="center-align">
    <button class="waves-effect waves-light btn" style="width:50%" @click="handleForm">Save Consent</button>
    </div>
  </div>
  </div>
</template>

<script>
const Swal = require('sweetalert2')
import { ref } from '@vue/reactivity';
import { onBeforeMount, onMounted, watch } from '@vue/runtime-core'
import { projectAuth, projectFirestore, timestamp } from '../firebase/config'
import {useRouter} from 'vue-router'
import onUpload from '../Composables/uploadImage'
import getStudent from '../Composables/getStudent'

  
export default {
    props: ['studentid'],
    setup(props){


    onMounted(() => {
        //init Materialize elements
        var elems = document.querySelectorAll('.datepicker')
        var instances = M.Datepicker.init(elems, {})
        var elems = document.querySelectorAll('select')
        var instances = M.FormSelect.init(elems, {})

        
    })

        const dec = ref()
        const decRead = ref(false)
        const orthoRead = ref(false)
        const ortho = ref()
        const perms = ref()
        const permsRead = ref(false)
        const router = useRouter()
        const image = ref()
        const image2 = ref()

        const support = ref()
        const progress = ref()
        const skills = ref()
        const hobby = ref()
        const chaplain = ref()
        const relate = ref()
        const health = ref()
        const aob = ref()
        const emotion = ref()


        

        const fname = ref()
        const lname = ref()
        const id = props.studentid
        const date = ref()
        const family = ref()
        const improved = ref()
        const subject = ref()
        const future = ref()
        const grades = ref()
        const orthoDetails = ref()
        var img
        var img2
        var upload
        var consent
        const imgMsg = ref(false)
        const conMsg = ref(false)
        const pp = ref()

        const dataMissingError = ref(false)
        const dataMissingErrorText = ref("There is some data missing, please ensure all boxes are filled.")
        console.log(props.studentid)

                const {student, error, load, studentId} = getStudent(props.studentid)
        load().then(() => {
            if(error.value){
            Swal.fire({
                    title: 'Error!',
                    text: 'Student record does not exist',
                    icon: 'question',
                    confirmButtonText: 'Close'
                }).then(() => {
                    router.push({name: 'ViewAllStudents'})
                })
                
            }
        fname.value = student.value.FirstName
        lname.value = student.value.LastName
        pp.value = student.value.PP

        console.log(student.value.fname)

        })


        const handleFile = (e) =>{
            image.value = e.target.files[0]
            imgMsg.value = true
            //console.log(image)
            //onUpload(image)
            
        }

        const handleFile2 = (e) =>{
            image2.value = e.target.files[0]
            conMsg.value = true
        }

        const handleChange = () => {
            
            if(dec.value == 'Other'){
                decRead.value = true
            }else{
                decRead.value = false
            }
        }

        const handleChangeOrtho = () => {
            
            if(ortho.value == 'true'){
                orthoRead.value = true
            }else{
                orthoRead.value = false
            }
        }

        const handleChangePerms = () => {
            
            if(perms.value == 'true'){
                permsRead.value = true
            }else{
                permsRead.value = false
            }
        }

        const handleForm = async () => {


            if (ortho.value == 'false'){
                orthoDetails.value = 'Not Applicable'
            }

            if(date.value == null || future.value == null || grades.value == null || improved.value == null || family.value == null || subject.value == null || orthoDetails.value == null || orthoDetails.value == "" || date.value == "" || improved.value == "" || future.value == "" || grades.value == "" || family.value == "" || subject.value == ""){
                dataMissingError.value = true
                Swal.fire({
                    title: 'Error!',
                    text: dataMissingErrorText.value,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else if(isNaN(new Date(date.value))){
                Swal.fire({
                    title: 'Error!',
                    text: 'Date is invalid',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else{

            Swal.fire({
                    title: 'Uploading Images',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                })
                Swal.showLoading()

            if(image.value == null){
                upload = "No Photo"
            }else{
                img = await onUpload(image.value)
                upload = img.value
            }
            Swal.close()            

            
            const reportData = {
                ChildID: props.studentid,
                ReportDate: new Date(date.value),
                Family: family.value,
                Improved: improved.value,
                Subject: subject.value,
                Future: future.value,
                Grades: grades.value,
                AddedBy: projectAuth.currentUser.email,
                Ortho: ortho.value,
                OrthoDetails: orthoDetails.value,
                DateSaved: timestamp(),
                Photo: upload
            }
            
            try{
                const response = await projectFirestore.collection('Reports').add(reportData)

                Swal.fire({
                    title: 'Success!',
                    text: 'Report data has been saved.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                


            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }



            if(pp.value){
                 if(support.value == null || progress.value == null || skills.value == null || hobby.value == null || chaplain.value == null || relate.value == null || health.value == null || aob.value == null || support.value == "" || progress.value == "" || skills.value == "" || hobby.value == "" || chaplain.value == "" || relate.value == "" || health.value == "" || aob.value == ""){
                dataMissingError.value = true
                Swal.fire({
                    title: 'Error!',
                    text: dataMissingErrorText.value,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else{
                const ropeReportData = {
                ChildID: props.studentid,
                ReportDate: new Date(date.value),
                Support: support.value,
                ProgressReceived: progress.value,
                NewSkills: skills.value,
                Hobbies: hobby.value,
                ChaplainResponse: chaplain.value,
                AddedBy: projectAuth.currentUser.email,
                RelationshipWithFam: relate.value,
                Emotionally: emotion.value,
                OtherHealth: health.value,
                DateSaved: timestamp(),
                AOB: aob.value
            }
            
            try{
                const response = await projectFirestore.collection('PPReports').add(ropeReportData)

                Swal.fire({
                    title: 'Success!',
                    text: 'Report data has been saved.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                router.push({name: 'Home'})


            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }

            }
            }else{
                router.push({name: 'Home'})
            }

            
                
           
               
            

            }
        
        }

        return{emotion, pp, conMsg, imgMsg, handleFile2, onUpload, handleFile, dec, decRead, handleChange, handleChangeOrtho, orthoRead, ortho, perms, permsRead, handleChangePerms, fname, lname, date, future, improved, grades, subject, family, orthoDetails, handleForm, dataMissingError, dataMissingErrorText, id, support, progress, skills, hobby, chaplain, relate, health, aob }
    }

}
</script>

<style>

</style>