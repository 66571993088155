<template>
  <div class="row">
    <div class="col m1 l1"></div>
    <div class="col s12 m10 l10">
    <ul class="collection" >
      <li class="collection-item avatar" style="padding-bottom:10px;" v-for="note in notes" :key = note.id>
          <NoteCard :note="note" />
      </li>
    </ul>
    </div>
  </div>
</template>

<script>
import NoteCard from './noteCard.vue'
import getNotes from '../Composables/getNotes'
import { ref } from '@vue/reactivity'
import { format, render, cancel, register } from 'timeago.js';


export default {
    components: { NoteCard },
    setup(){
        const {notes, errorNote, loadNote} = getNotes()
        loadNote()
        console.log(notes)
        const date = ref()
        const img = ref(false)
        const timeAgo = ref()

        const time = (timeLogged) => {
        console.log(timeLogged)
        timeAgo.value = format(timeLogged.seconds * 1000)
        return timeAgo.value
        }

        //if(props.note.Photo === "No Photo"){
            
        //}else{
        //    img.value = true
        //}

        return{ notes, time, img }

    }

}
</script>

<style>

</style>