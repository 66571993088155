<template>
<div class="row">
  <div class="center"><h5>View All Students</h5></div>

  <!--<div class="switch">
            <label>
                All Students
            <input type="checkbox" @change="ppSearch" v-model="pp">
                <span class="lever"></span>
                Project Possible Only
            </label><br>

            <br>
            <label>
                All Students
            <input type="checkbox" @change="decSpons" v-model="spons">
                <span class="lever"></span>
                Sponsored at DEC
            </label>
            <div style="margin-top:10px;" class="center">{{ students.length }} students</div>
            <div class="row">
            <div class="col l4"></div>   
            <div class="input-field col l4">
                <input id="search" type="text" v-model="search" @keyup="searchIt">
            <label for="search">Search</label>
            </div>
            
            </div>
            <div class="row">
                <div class="col l4"></div>
                    <div class="input-field col l4">
              <input type="text" class="datepicker" v-model.lazy="dateSearch">
              <label>Added After</label>
            </div>
            </div>
            <div class="row">
                <div class="col l4 m2">

                </div>
                <div class="col l4 m8">
                    <textarea  v-if="displayNames" v-model="names" rows="20"></textarea>
                    <div class="btn red" @click="cancelExport" v-if="displayNames">Cancel Export</div>
                </div>
            </div>
            

            <div class="row">
                <div class="col m4 l4"></div>
            <div class="col m4 l4">
                <a v-if="!filtered" class="waves-effect waves-light btn" @click="dateFilter">Filter By Date</a>
                <a v-else class="waves-effect waves-light red btn" @click="cancelFilter">Cancel Date Filter</a>
                <a class="waves-effect waves-light btn" @click="exportNames">Export Names</a>
            </div>
            
            </div>
        </div>
        </div>-->
  <div class="row">
        <Spinner v-if="resultSearch.length == 0"/>

        <!--<div v-if="pp || search != null || last || filtered || photo || spons">
            <p class="center">Filter includes {{ result.length }} students</p>
            <div class="center" v-if="resultNumber">No Results</div>
            <StudentList v-else :students = "result"/>
        </div>-->

        <div v-else>
            <div class = "center" v-if="resultNumber">No Students</div>
             <StudentList  :students = "students"/>
        </div>
       
        
  </div>
  </div>
</template>

<script>
  
import StudentCard from '../components/StudentCard.vue'
import getStudents from '../Composables/getStudents'
import getDecStudents from '../Composables/getDecStudents'
import studentSelectList from '../components/studentSelectList.vue'
import StudentList from '../components/StudentList.vue'
import { ref } from '@vue/reactivity'
import Spinner from '../components/Spinner.vue'
import { onBeforeMount, onMounted, watch } from '@vue/runtime-core'

export default {
    components: {StudentList, Spinner},
    setup() {

        onMounted(() => {
            var elems = document.querySelectorAll('.datepicker')
            var instances = M.Datepicker.init(elems, {})

        })
        var result = ref()
        const {students, resultSearch, error, load} = getStudents()
        load()
        

        console.log(result.value)
        
        var search = ref()
        var showResults = ref()
        showResults.value = false
        var resultNumber = ref(false)
        const dateSearch = ref()
        const filtered = ref(false)
        const names = ref()
        const displayNames = ref(false)
        const spons = ref(false)


        const pp = ref(false)
        const last = ref(false)
        const photo = ref(false)

        const ppSearch = () => {
            search.value = null
            if(pp.value){
                
                students.value = students.value.filter(student => student.PP)
            }else{
                students.value = resultSearch.value
                spons.value = false
            }

        }

        const decSpons = () => {
            search.value = null
            if(spons.value){
                students.value = students.value.filter(student => student.School == "DEC")
            }else{
                students.value = resultSearch.value
                pp.value = false
            }

        }

        const photoConsent = () => {
            search.value = null
            if(photo.value){
                students.value = students.value.filter(student => student.Consent == 'No Photo')
            }else{
                students.value = resultSearch.value
            }

        }

        const exportNames = () => {
            var i = 0
            names.value = ""
            while(students.value.length > i){
                names.value = names.value + "\n" + students.value[i].FirstName+" "+students.value[i].LastName
                i++
            }
            displayNames.value = true

        }

        const cancelExport = () => {
             students.value = resultSearch.value
             displayNames.value = false
             names.value = ""
        }

        const lastWeek = () => {
            search.value = null
            var time = new Date().getTime()/1000
            if(last.value){
                console.log("go")
                result.value = students.value.filter(student => student.DateAdded.seconds > time - 60480)
                console.log(result.value.length)
            }

        }

        const dateFilter = () => {
            search.value = null
            pp.value = false
            spons.value = false
            console.log(dateSearch.value)
            var time = new Date().getTime()/1000
            if(dateSearch.value){
                var filDate = new Date(dateSearch.value).getTime()/1000
                console.log(filDate)
                console.log(time)
                console.log(students.value[0].DateAdded.seconds)
                students.value = students.value.filter(student => filDate - student.DateAdded.seconds < 0)
                filtered.value = true
            }

        }

        const cancelFilter = () => {
            students.value = resultSearch.value
            filtered.value = false
            dateSearch.value = null
        }

        const searchIt = () => {
            cancelFilter()
            students.value = resultSearch.value
            if(pp.value){
            students.value = students.value.filter(student => {
                const searchStr = search.value.toLowerCase()
                const name = student.FirstName + ' ' + student.LastName
                //console.log(name)
                console.log(student.pp)

                if(name.toLowerCase().includes(searchStr) && student.PP){
                    return true
                }else{
                    return false
                }
            })
            }else if(spons.value){
                students.value = students.value.filter(student => {
                const searchStr = search.value.toLowerCase()
                const name = student.FirstName + ' ' + student.LastName
                //console.log(name)

                if(name.toLowerCase().includes(searchStr) && student.School == "DEC"){
                    return true
                }else{
                    return false
                }
            })

            }else{
                 students.value = students.value.filter(student => {
                const searchStr = search.value.toLowerCase()
                const name = student.FirstName + ' ' + student.LastName
                //console.log(name)

                if(name.toLowerCase().includes(searchStr)){
                    return true
                }else{
                    return false
                }
            })

            }

        

        if(students.value.length == 0){
            resultNumber.value = true
        }else{
            resultNumber.value = false
        }
        }

        return{
            students,
            error,
            pp,
            ppSearch,
            result,
            search,
            searchIt,
            showResults,
            resultNumber,
            lastWeek,
            last,
            dateFilter,
            dateSearch,
            filtered,
            cancelFilter,
            photoConsent,
            photo,
            exportNames,
            displayNames,
            names,
            spons,
            decSpons,
            resultSearch,
            cancelExport
        }
    }

}
</script>

<style>

</style>