import { ref } from 'vue'
import firebase from '@firebase/app-compat';
import storage from 'firebase/compat/storage'

    const onUpload = async(imageData) => {
        const url1 = ref("test")
        const storageRef = await firebase.storage().ref(imageData.name).put(imageData)
        
        await storageRef.ref.getDownloadURL().then((url)=>{
            //console.log(url)
            url1.value = url
            //console.log("link" + url1.value)
            
          });

          return url1
        
        
      }

      export default onUpload
  