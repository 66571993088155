<template>
  <div class="container">    
      <div class="container" style="padding-top: 40px; padding-bottom: 40px;">
       <h4 class="center">Enter Log In Details</h4>
       
      <form @submit.prevent>
        <div class="row">
            <div class="input-field col s12">
                <input id="email1" type="email" class="validate" v-model="email1">
                <label for="email1">Email</label>
            </div>
        </div>
        <div class="row">
            <div class="input-field col s12">
                <input id="password1" type="password" class="validate" v-model="password1">
                <label for="password1">Password</label>
            </div>
        </div>
        <a class="waves-effect waves-light btn" @click="handleSubmit">Log In</a>


      </form>

      </div>
     
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import useSignup from '../../Composables/SignUp'
import {useRouter} from 'vue-router'
import useLogin from '../../Composables/useLogin'


const Swal = require('sweetalert2')



export default {
    setup(){
        const{error, login} = useLogin()

        const name = ref('')
        const email1 = ref('')
        const password1 = ref('')
        const router = useRouter()

        const handleSubmit = async() => {
            Swal.fire({
                    title: 'Checking Data',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                })
                Swal.showLoading()
            await login(email1.value, password1.value)
            Swal.close()
            if(error.value){
                
                Swal.fire({
                    title: 'Error!',
                   text: error.value,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else{
                 router.push({name: 'Home'})

            }    
        }


        return {name, email1, password1, handleSubmit}

    }

}
</script>

<style>

</style>