export async function GetPlaceName(lat,long){
    //console.log(lat,long,"***")
    const stringBuild = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng="+lat+","+long+"&location_type=APPROXIMATE&key=AIzaSyDLvaUkytO-bySfUT4hodvH18-kv9d3b-w")
    const arr = await stringBuild.json()
    //console.log(arr)
    
    if(arr.results.length == 0){
        //console.log("Locality not known")
        return("Locality not known")
    }else{
    //console.log(arr.results[0].formatted_address)
    return(arr.results[0].formatted_address)
    }   
}