<template>
<div class="container">
   <!--<div class="row">
    <form class="col s12" @submit.prevent>
      <div class="row">
          <h5 class="center">New Transfer Request</h5>
        <div class="input-field col s4">
          <input id="amount" type="text" class="validate" v-model="donor">
          <label for="amount">Amount</label>
        </div>
        <div class="input-field col s4">
          <input id="currency" type="text" class="validate" v-model="currency">
          <label for="currency">Currency</label>
        </div>
        <div class="input-field col s4">
          <input id="fund" type="text" class="validate" v-model="fund">
          <label for="fund">Fund</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12 m12">
          <input id = "note" type="text" class="validate" v-model='note'>
          <label for="note">Note</label>
        </div>
      </div>    
    </form>
    <div class="center-align">
    <button class="waves-effect waves-light btn" style="width:50%" @click="addNew">Add New Item</button>
    <button class="waves-effect waves-light btn" style="width:50%" @click="handleForm">Save Receipt</button>
    <button v-if="done" class="waves-effect waves-light btn" style="width:50%" @click="goToReceipt">View Receipt</button>
    </div>
  </div>
  </div>

  <div>-->
    <div :key="refresh">
    <div v-for="(text, index) in texts" :key="index">
      <div class="row">
      <div class="input-field col s4">
        
          <input placeholder="Amount" type="text" class="validate" v-model="amounts[index]">  
           
        </div>
        <div class="input-field col s4">
          <input placeholder="Currency" type="text" class="validate" v-model="currency[index]">
        </div>
        <div class="input-field col s4">
          <input placeholder="Fund" type="text" class="validate" v-model="funds[index]">
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12 m12">
          <input placeholder="Notes" type="text" class="validate" v-model='notes[index]'>
        </div>
      </div>
      <button class="waves-effect waves-light btn red" style="width: 20%;font-size:10px;" @click="deleteText(index)">Delete Row</button>
    </div>
    <button class="waves-effect waves-light btn" style="width: 20%;font-size:10px;" @click="addText">Add Row</button>
  </div>
  </div>
</template>

<script>
const Swal = require('sweetalert2')
import { ref } from '@vue/reactivity';
import { onBeforeMount, onMounted, watch } from '@vue/runtime-core'
import { projectAuth, projectFirestore, timestamp } from '../firebase/config'
import {useRouter} from 'vue-router'
import onUpload from '../Composables/uploadImage'
  
export default {
    setup(){


    onMounted(() => {
        //init Materialize elements
        var elems = document.querySelectorAll('.datepicker')
        var instances = M.Datepicker.init(elems, {})
        var elems = document.querySelectorAll('select')
        var instances = M.FormSelect.init(elems, {})

        
    })


        

        const currency = [''];
        const done = ref(false)
        const link = ref()
        const texts = [''];
        const amounts = [''];
        const funds = [''];
        const notes = [''];
        const refresh = ref(1);
        
        const dataMissingError = ref(false)
        const dataMissingErrorText = ref("There is some data missing, please ensure all boxes are filled.")

        const goToReceipt = () => {
            window.location.href = link.value;
        }

    const addText = () => {
      console.log(texts);
      texts.push('');
      refresh.value = refresh.value + 1 // add new empty text box
    }
    const deleteText = (index) => {
      console.log(texts);
      texts.splice(index, 1);
      refresh.value = refresh.value + 1 // remove text box at index
    }



        const handleForm = async () => {
            console.log(date.value);


            if(donor.value == null || date.value == null || amount.value == null || type.value == null || donor.value == "" || date.value == "" || amount.value == "" || type.value == ""){
                dataMissingError.value = true
                Swal.fire({
                    title: 'Error!', 
                    text: dataMissingErrorText.value,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else if(isNaN(new Date(date.value))){
                Swal.fire({
                    title: 'Error!',
                    text: 'Date is invalid',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else{

                Swal.showLoading()
                function toTimestamp(strDate){
                    var datum = Date.parse(strDate);
                    //console.log(datum)
                return datum;
                }

            //const response = await fetch('https://hook.integromat.com/rku863u2noq1wqmrtwdc9dtds33zapj1?Donor='+encodeURIComponent(donor.value.trim())+'&Amount='+amount.value+'&Date='+toTimestamp(date.value)+'&Type='+type.value);
            //link.value = await response.text()
            //Swal.close()  
            //Swal.fire({
            //icon: 'success',
            //title: 'Receipt saved to Google Drive',
            //footer: '<a href="'+link+'">View Receipt</a>'
            //})
            done.value = true


            }
        
        }

        return{currency, notes, amounts, funds, handleForm, done, goToReceipt, texts, addText, deleteText, refresh}
    }

}
</script>

<style>

</style>