<template>
<div class="container">
   <div class="row">
    <form class="col s12" @submit.prevent>
      <div class="row">
          <h5 class="center">New Charitable Donation Receipt</h5>
        <div class="input-field col s6">
          <input id="donore" type="text" class="validate" v-model="donor">
          <label for="donor">Donor Name</label>
        </div>
        <div class="input-field col s12 m6">
          <input id = "date" type="text" class="datepicker" v-model.lazy="date">
          <label for="date">Donation Date</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12 m6">
          <input id = "amount" type="number" class="validate" v-model='amount'>
          <label for="amount">Donation Amount</label>
        </div>
        <div class="input-field col s12 m6">
        <select v-model="type" >
            <option value="Cash">Cash</option>
            <option value="Goods">Goods</option>    
            <option value="Services">Services</option>
        </select>
        <label>Type</label>
        </div>
      </div>    
    </form>
    <div class="center-align">
    <button class="waves-effect waves-light btn" style="width:50%" @click="handleForm">Save Receipt</button>
    <button v-if="done" class="waves-effect waves-light btn" style="width:50%" @click="goToReceipt">View Receipt</button>
    </div>
  </div>
</div>
</template>

<script>
const Swal = require('sweetalert2')
import { ref } from '@vue/reactivity';
import { onBeforeMount, onMounted, watch } from '@vue/runtime-core'
import { projectAuth, projectFirestore, timestamp } from '../firebase/config'
import {useRouter} from 'vue-router'
import onUpload from '../Composables/uploadImage'
  
export default {
    setup(){


    onMounted(() => {
        //init Materialize elements
        var elems = document.querySelectorAll('.datepicker')
        var instances = M.Datepicker.init(elems, {})
        var elems = document.querySelectorAll('select')
        var instances = M.FormSelect.init(elems, {})

        
    })


        

        const donor = ref()
        const date = ref()
        const amount = ref()
        const type = ref()
        const done = ref(false)
        const link = ref()
        
        const dataMissingError = ref(false)
        const dataMissingErrorText = ref("There is some data missing, please ensure all boxes are filled.")

        const goToReceipt = () => {
            window.location.href = link.value;
        }



        const handleForm = async () => {
            console.log(date.value);


            if(donor.value == null || date.value == null || amount.value == null || type.value == null || donor.value == "" || date.value == "" || amount.value == "" || type.value == ""){
                dataMissingError.value = true
                Swal.fire({
                    title: 'Error!', 
                    text: dataMissingErrorText.value,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else if(isNaN(new Date(date.value))){
                Swal.fire({
                    title: 'Error!',
                    text: 'Date is invalid',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else{

                Swal.showLoading()
                function toTimestamp(strDate){
                    var datum = Date.parse(strDate);
                    //console.log(datum)
                return datum;
                }

            const response = await fetch('https://hook.integromat.com/rku863u2noq1wqmrtwdc9dtds33zapj1?Donor='+encodeURIComponent(donor.value.trim())+'&Amount='+amount.value+'&Date='+toTimestamp(date.value)+'&Type='+type.value);
            link.value = await response.text()
            Swal.close()  
            Swal.fire({
            icon: 'success',
            title: 'Receipt saved to Google Drive',
            footer: '<a href="'+link+'">View Receipt</a>'
            })
            done.value = true


            }
        
        }

        return{donor, date, amount, type, handleForm, done, goToReceipt}
    }

}
</script>

<style>

</style>