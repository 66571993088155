<template>
      <span class="title">{{ report.ChildID }}</span>      
</template>

<script>
import { ref } from '@vue/reactivity'
import { format, render, cancel, register } from 'timeago.js';
import { projectFirestore } from '../firebase/config'


export default {
    props: ['report'],
    setup(props) {
        const timeAgo = ref()
        //timeAgo.value = format(props.student.DateAdded.seconds * 1000)
        const added = ref()
        //added.value = props.student.DateAdded.seconds


         
        
  
        


        return {
            timeAgo,
            
        }

}
}
</script>

<style>

</style>