<template>
  <div class="row">
    <div class="col m1 l1"></div>
    <div class="col s12 m10 l10">
    <ul class="collection" >
      <li class="collection-item avatar" style="padding-bottom:10px;" v-for="report in reports" :key = report.id>
          <ReportCard :report="report" />
      </li>
    </ul>
    </div>
  </div>
</template>

<script>
import ReportCard from './ReportCard.vue'
export default {
    props:['reports'],
    components: { ReportCard },
    setup(props){

    }

}
</script>

<style>

</style>