<template>
<div class="container">
   <div class="row">
    <form class="col s12" @submit.prevent>
      <div class="row">
          <h5 class="center">New Report for {{fname}} {{lname}}</h5>
        <div class="input-field col s6">
          <input disabled id="last_name" type="text" v-model="id">
          <label class="active" for="last_name">Child ID</label>
        </div>
        <div class="input-field col s12 m6">
          <input id = "date" type="text" class="datepicker" v-model.lazy="date">
          <label for="date">Report Date</label>
        </div>
      </div>

      <div class="row">
          <div class="input-field col s12 m6">
                    <textarea id="family" class="materialize-textarea" v-model="family"></textarea>
                <label for="family">How is their family? How has sponsorship helped them?</label>
               
          </div>
          <div class="input-field col s12 m6">
                    <textarea id="improved" class="materialize-textarea" v-model="improved"></textarea>
                <label for="improved">What has improved over the last 6 months? Also what have they found difficult?</label>
               
           
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12 m6">
                    <textarea id="subject" class="materialize-textarea" v-model="subject"></textarea>
                <label for="sunject">What is the child's favourite subject?</label>
               
          </div>
          <div class="input-field col s12 m6">
                    <textarea id="future" class="materialize-textarea" v-model="future"></textarea>
                <label for="future">What is the child's favourite hobby/sport? Do they have a favourite team?</label>
               
           
          </div>
        </div>
          <div class="row">
          <div class="input-field col s12 m6">
                    <textarea id="grades" class="materialize-textarea" v-model="grades"></textarea>
                <label for="grades">Latest Grades</label>
               
          </div>
        <div class="input-field col s12 m6">
        <select v-model="ortho" @change = "handleChangeOrtho">
            <option value=false>No</option>
            <option value=true>Yes</option>    
        </select>
        <label>Has this child received Orthopaedic treatment?</label>

        </div>
        <div v-if="orthoRead">
        <div class="input-field col s12 m6">
          <textarea id="orthoDetails" class="materialize-textarea" v-model="orthoDetails"></textarea>
        <label for="orthoDetails">Please give full details of the treatment</label>
        </div>
      </div>
      <div class="input-field col s12 m6">
        <select v-model="year" >
            <option value="" disabled selected>Select a Grade</option>
            <option value="pp 1">PP 1</option>
            <option value="pp 2">PP 2</option>    
            <option value="Grade 1">Grade 1</option>
            <option value="Grade 2">Grade 2</option>
            <option value="Grade 3">Grade 3</option>
            <option value="Grade 4">Grade 4</option>
            <option value="Grade 5">Grade 5</option>
            <option value="Grade 6">Grade 6</option>
            <option value="Grade 7">Grade 7</option>
            <option value="Grade 8">Grade 8</option>
            <option value="Form 1">Form 1</option>
            <option value="Form 2">Form 2</option>
            <option value="Form 3">Form 3</option>
            <option value="Form 4">Form 4</option>
            <option value="Higher Education Year 1">Higher Education Year 1</option>
            <option value="Higher Education Year 2">Higher Education Year 2</option>
            <option value="Higher Education Year 3">Higher Education Year 3</option>
            <option value="Higher Education Year 4">Higher Education Year 4</option>
        </select>
        <label>Current Grade</label>
        </div>
        </div>

    <div v-if="pp">
        <div class="row">
          <div class="input-field col s12 m6">
                    <textarea id="support" class="materialize-textarea" v-model="support"></textarea>
                <label for="support">What do they find hard? What is being done to support them?</label>
               
          </div>
          <div class="input-field col s12 m6">
                    <textarea id="progress" class="materialize-textarea" v-model="progress"></textarea>
                <label for="progress">Is there anything else that may affect their progress?</label>
               
           
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12 m6">
                    <textarea id="skills" class="materialize-textarea" v-model="skills"></textarea>
                <label for="skills">Has the child learnt new personal skills?</label>
               
          </div>
          <div class="input-field col s12 m6">
                    <textarea id="hobby" class="materialize-textarea" v-model="hobby"></textarea>
                <label for="hobby">Does the child have any hobbies or interests?</label>
               
           
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12 m6">
                    <textarea id="chaplain" class="materialize-textarea" v-model="chaplain"></textarea>
                <label for="chaplain">How has the child responded to the chaplain?</label>
               
          </div>
          <div class="input-field col s12 m6">
                    <textarea id="relate" class="materialize-textarea" v-model="relate"></textarea>
                <label for="relate">How is their relationship with their family?</label>
               
           
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12 m6">
                    <textarea id="relate" class="materialize-textarea" v-model="emotion"></textarea>
                <label for="relate">How is the child emotionally?</label>
               
          </div>
          <div class="input-field col s12 m6">
                    <textarea id="health" class="materialize-textarea" v-model="health"></textarea>
                <label for="health">How is the child's health?</label>
               
           
          </div>
        </div>
         <div class="row">
          <div class="input-field col s12 m12">
                    <textarea id="aob" class="materialize-textarea" v-model="aob"></textarea>
                <label for="aob">Is there anything else you would like to share?</label>
               
          </div>
        </div>
           

    </div>



<div class="row">
        
        <div class="file-field input-field col m12 s12">
      <div class="btn" style="width:100%">
        <span>Upload Photo of Child</span>  
        <input type="file" @change="handleFile" accept="image/*">
      </div>
    <div class="btn green" v-if="imgMsg"  style="width:100%; margin-top:5px;">
          <i class="large material-icons left">check</i>Photo ready to upload
        
      </div>
        </div> 

        </div>      
    </form>
    <div class="center-align">
    <button class="waves-effect waves-light btn" style="width:50%" @click="handleForm">Save Report</button>
    </div>
  </div>
  </div>
</template>

<script>
const Swal = require('sweetalert2')
import { ref } from '@vue/reactivity';
import { onBeforeMount, onMounted, watch } from '@vue/runtime-core'
import { projectAuth, projectFirestore, timestamp } from '../firebase/config'
import {useRouter} from 'vue-router'
import onUpload from '../Composables/uploadImage'
import getStudent from '../Composables/getStudent'

  
export default {
    props: ['studentid'],
    setup(props){


    onMounted(() => {
        //init Materialize elements
        var elems = document.querySelectorAll('.datepicker')
        var instances = M.Datepicker.init(elems, {})
        var elems = document.querySelectorAll('select')
        var instances = M.FormSelect.init(elems, {})

        
    })

        const dec = ref()
        const decRead = ref(false)
        const orthoRead = ref(false)
        const ortho = ref()
        const perms = ref()
        const permsRead = ref(false)
        const router = useRouter()
        const image = ref()
        const image2 = ref()

        const support = ref()
        const progress = ref()
        const skills = ref()
        const hobby = ref()
        const chaplain = ref()
        const relate = ref()
        const health = ref()
        const aob = ref()
        const emotion = ref()
        const year =ref()


        

        const fname = ref()
        const lname = ref()
        const id = props.studentid
        const date = ref()
        const family = ref()
        const improved = ref()
        const subject = ref()
        const future = ref()
        const grades = ref()
        const orthoDetails = ref()
        var img
        var img2
        var upload
        var consent
        const imgMsg = ref(false)
        const conMsg = ref(false)
        const pp = ref()

        const dataMissingError = ref(false)
        const dataMissingErrorText = ref("There is some data missing, please ensure all boxes are filled.")

                const {student, error, load, studentId} = getStudent(props.studentid)
        load().then(() => {
            if(error.value){
            Swal.fire({
                    title: 'Error!',
                    text: 'Student record does not exist',
                    icon: 'question',
                    confirmButtonText: 'Close'
                }).then(() => {
                    router.push({name: 'ViewAllStudents'})
                })
                
            }
        fname.value = student.value.FirstName
        lname.value = student.value.LastName
        pp.value = student.value.PP

        console.log(student.value.fname)

        })


        const handleFile = (e) =>{
            image.value = e.target.files[0]
            imgMsg.value = true
            //console.log(image)
            //onUpload(image)
            
        }

        const handleFile2 = (e) =>{
            image2.value = e.target.files[0]
            conMsg.value = true
        }

        const handleChange = () => {
            
            if(dec.value == 'Other'){
                decRead.value = true
            }else{
                decRead.value = false
            }
        }

        const handleChangeOrtho = () => {
            
            if(ortho.value == 'true'){
                orthoRead.value = true
            }else{
                orthoRead.value = false
            }
        }

        const handleChangePerms = () => {
            
            if(perms.value == 'true'){
                permsRead.value = true
            }else{
                permsRead.value = false
            }
        }

        const handleForm = async () => {


            if (ortho.value == 'false'){
                orthoDetails.value = 'Not Applicable'
            }

            if(date.value == null || future.value == null || grades.value == null || improved.value == null || family.value == null || subject.value == null || orthoDetails.value == null || orthoDetails.value == "" || date.value == "" || improved.value == "" || future.value == "" || grades.value == "" || family.value == "" || subject.value == "" || year.value == "" || year.value == null){
                dataMissingError.value = true
                Swal.fire({
                    title: 'Error!',
                    text: dataMissingErrorText.value,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else if(isNaN(new Date(date.value))){
                Swal.fire({
                    title: 'Error!',
                    text: 'Date is invalid',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else{

            Swal.fire({
                    title: 'Uploading Images',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                })
                Swal.showLoading()

            if(image.value == null){
                upload = "No Photo"
            }else{
                img = await onUpload(image.value)
                upload = img.value
            }
            Swal.close()            

            
            const reportData = {
                ChildID: props.studentid,
                ReportDate: new Date(date.value),
                Family: family.value,
                Improved: improved.value,
                Subject: subject.value,
                Future: future.value,
                Grades: grades.value,
                AddedBy: projectAuth.currentUser.email,
                Ortho: ortho.value,
                OrthoDetails: orthoDetails.value,
                DateSaved: timestamp(),
                Photo: upload,
                ProForma: null
            }
            console.log(props.studentid)
            
            try{
                const response = await projectFirestore.collection('Reports').add(reportData)
                const response2 = await projectFirestore.collection('Students').doc(props.studentid).update({Grade: year.value})

                Swal.fire({
                    title: 'Success!',
                    text: 'Report data has been saved.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                


            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }



            if(pp.value){
                 if(support.value == null || progress.value == null || skills.value == null || hobby.value == null || chaplain.value == null || relate.value == null || health.value == null || aob.value == null || support.value == "" || progress.value == "" || skills.value == "" || hobby.value == "" || chaplain.value == "" || relate.value == "" || health.value == "" || aob.value == ""){
                dataMissingError.value = true
                Swal.fire({
                    title: 'Error!',
                    text: dataMissingErrorText.value,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else{
                const ropeReportData = {
                ChildID: props.studentid,
                ReportDate: new Date(date.value),
                Support: support.value,
                ProgressReceived: progress.value,
                NewSkills: skills.value,
                Hobbies: hobby.value,
                ChaplainResponse: chaplain.value,
                AddedBy: projectAuth.currentUser.email,
                RelationshipWithFam: relate.value,
                Emotionally: emotion.value,
                OtherHealth: health.value,
                DateSaved: timestamp(),
                AOB: aob.value
            }
            
            try{
                const response = await projectFirestore.collection('PPReports').add(ropeReportData)

                Swal.fire({
                    title: 'Success!',
                    text: 'Report data has been saved.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                router.push({name: 'Home'})


            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }

            }
            }else{
                router.push({name: 'Home'})
            }

            
                
           
               
            

            }
        
        }

        return{year, emotion, pp, conMsg, imgMsg, handleFile2, onUpload, handleFile, dec, decRead, handleChange, handleChangeOrtho, orthoRead, ortho, perms, permsRead, handleChangePerms, fname, lname, date, future, improved, grades, subject, family, orthoDetails, handleForm, dataMissingError, dataMissingErrorText, id, support, progress, skills, hobby, chaplain, relate, health, aob }
    }

}
</script>

<style>

</style>