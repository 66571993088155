<template>
     {{ student.FirstName }}
</template>

<script>

export default {
    props: ['student'],
    setup(props) {
        //console.log(props.student.LastName)
        
        
  
        


        return {
                        
        }

}
}
</script>

<style>

</style>