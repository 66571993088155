<template>
  <div class="row">
    <div class="col s12">
      <ul class="tabs">
        <li class="tab col s3"><a href="#test1">DEC Students</a></li>
        <li class="tab col s3"><a href="#test2">Secondary Students</a></li>
        <li class="tab col s3"><a href="#test3">Higher Education Students</a></li>
        <li class="tab col s3"><a href="#test4">Archived Students</a></li>
      </ul>
    </div>
    <div id="test1" class="col s12">
        <div class="row">
            <div class="col l4"></div>   
            <div class="input-field col l4">
                <input id="search" type="text" v-model="decSearch" @keyup="searchDEC">
            <label for="search">Search</label>
            <a class="waves-effect waves-light btn" @click="exportNames">Export Names</a>

            <div class="col l4 m8">
                    <textarea  v-if="displayNames" v-model="names" rows="20"></textarea>
                    <div class="btn red" @click="cancelExport" v-if="displayNames">Cancel Export</div>
                </div>
            </div>
            
            
            </div>
            <h5 class="center">{{decKids.length}} Student(s)</h5>
      
      
    <div class="row">
    <div class="col 8" style="padding-left:10%; padding-right:10%; width: 100%!important">
      <ul class="collection">
      <li class="collection-item avatar" style="padding-bottom:10px;" v-for="decKid in decKids" :key = decKid.id>
          <ReportCard :student="decKid" />
      </li>
    </ul>
    </div>
    </div>
    </div>
    <div id="test2" class="col s12">
          <div class="row">
            <div class="col l4"></div>   
            <div class="input-field col l4">
                <input id="search" type="text" v-model="secSearch" @keyup="searchSec">
            <label for="search">Search</label>
                            
            </div>
            
            </div>
                        <h5 class="center">{{secondStudents.length}} Student(s)</h5>

      
      
    <div class="row">
    <div class="col 8" style="padding-left:10%; padding-right:10%; width: 100%!important">
      <ul class="collection">
      <li class="collection-item avatar" style="padding-bottom:10px;" v-for="secKid in secondStudents" :key = secKid.id>
          <StudentCard :student="secKid" />
      </li>
    </ul>
    </div>
    </div>
    </div>
    <div id="test3" class="col s12">
           <div class="row">
            <div class="col l4"></div>   
            <div class="input-field col l4">
                <input id="search" type="text" v-model="highSearch" @keyup="searchHigh">
            <label for="search">Search</label>
            </div>
            
            </div>
                        <h5 class="center">{{highStudents.length}} Student(s)</h5>

      
      
    <div class="row">
    <div class="col 8" style="padding-left:10%; padding-right:10%; width: 100%!important">
      <ul class="collection">
      <li class="collection-item avatar" style="padding-bottom:10px;" v-for="highKid in highStudents" :key = highKid.id>
          <StudentCard :student="highKid" />
      </li>
    </ul>
    </div>
    </div>
    </div>
    <div id="test4" class="col s12">
       <div class="row">
            <div class="col l4"></div>   
            <div class="input-field col l4">
                <input id="search" type="text" v-model="archiveSearch" @keyup="searchArchive">
            <label for="search">Search</label>
            </div>
            
            </div>
                        <h5 class="center">{{archiveStudents.length}} Student(s)</h5>

      
      
    <div class="row">
    <div class="col 8" style="padding-left:10%; padding-right:10%; width: 100%!important">
      <ul class="collection">
      <li class="collection-item avatar" style="padding-bottom:10px;" v-for="arcKid in archiveStudents" :key = arcKid.id>
          <ReportCard :student="arcKid" />
      </li>
    </ul>
    </div>
    </div>
    </div>
  </div>

</template>


<script>
import { onBeforeMount, onMounted, watch } from '@vue/runtime-core'
import getDecStudents from '../Composables/getDecStudents'
import getSecondStudents from '../Composables/getSecondaryStudents'
import getHighStudents from '../Composables/getHighStudents'
import getArchiveStudents from '../Composables/getArchiveStudents'
import { ref } from '@vue/reactivity'






import StudentCard from './StudentCard.vue'
import ReportCard from './ReportCardMain.vue'

export default {
    props:['students'],
    components: { StudentCard },
    setup(props){
      onMounted(() => {
            var elems = document.querySelectorAll('.tabs')
            var instance = M.Tabs.init(elems, {})

        })

        const {decKids, resultSearch, error, load} = getDecStudents()
        load()

        const {secondStudents, resultSearch2, error2, load2} = getSecondStudents()
        load2()

        const {highStudents, resultSearch3, error3, load3} = getHighStudents()
        load3()

        const {archiveStudents, resultSearch4, error4, load4} = getArchiveStudents()
        load4()
        
        var decSearch = ref()

        const searchDEC = () => {
            decKids.value = resultSearch.value
            decKids.value = decKids.value.filter(decKid => {
                const searchStr = decSearch.value.toLowerCase()
                const name = decKid.FirstName + ' ' + decKid.LastName

                if(name.toLowerCase().includes(searchStr)){
                    return true
                }else{
                    return false
                }
            })
        }

        var secSearch = ref()

        const searchSec = () => {
            secondStudents.value = resultSearch2.value
            secondStudents.value = secondStudents.value.filter(secKid => {
                const searchStr2 = secSearch.value.toLowerCase()
                const name = secKid.FirstName + ' ' + secKid.LastName

                if(name.toLowerCase().includes(searchStr2)){
                    return true
                }else{
                    return false
                }
            })
        }

        var highSearch = ref()

        const searchHigh = () => {
            highStudents.value = resultSearch3.value
            highStudents.value = highStudents.value.filter(highKid => {
                const searchStr3 = highSearch.value.toLowerCase()
                const name = highKid.FirstName + ' ' + highKid.LastName

                if(name.toLowerCase().includes(searchStr3)){
                    return true
                }else{
                    return false
                }
            })
        }

        var archiveSearch = ref()

        const searchArchive = () => {
            archiveStudents.value = resultSearch4.value
            archiveStudents.value = archiveStudents.value.filter(arcKid => {
                const searchStr4 = archiveSearch.value.toLowerCase()
                const name = arcKid.FirstName + ' ' + arcKid.LastName

                if(name.toLowerCase().includes(searchStr4)){
                    return true
                }else{
                    return false
                }
            })
        }

        const names = ref()
        const displayNames = ref(false)

        const exportNames = () => {
            var i = 0
            names.value = ""
            while(decKids.value.length > i){
                names.value = names.value + "\n" + decKids.value[i].FirstName+" "+decKids.value[i].LastName
                i++
            }
            displayNames.value = true

        }
           

        return{
          secondStudents,
          decKids,
          resultSearch,
          resultSearch2,
          error,
          error2,
          error3,
          resultSearch3,
          highStudents,
          error4,
          resultSearch4,
          archiveStudents,
          searchDEC,
          decSearch,
          secSearch,
          searchSec,
          searchHigh,
          highSearch,
          archiveSearch,
          searchArchive,
          exportNames,
          names,
          displayNames
      
    }


    }

}


      
</script>

<style>

.tabs .tab a {
  color: teal;
  /*Custom Text Color*/
}

.tabs .tab a:hover {
  color:teal;
  /*Custom Color On Hover*/
}

.tabs .tab a:focus.active {
  color:teal;
  /*Custom Text Color While Active*/
  background-color: rgba(38, 166, 154, 0.2);
  /*Custom Background Color While Active*/
}

.tabs .tab .active {
  color:teal;
  /*Custom Text Color While Active*/
  background-color: rgba(38, 166, 154, 0.2);
  /*Custom Background Color While Active*/
}

.tabs .indicator {
  background-color:#26a69a;
  /*Custom Color Of Indicator*/
}

</style>