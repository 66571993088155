<template>
  <div class="row">
        <NoteList :notes = "notes"/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import NoteList from '../components/NoteList.vue'
import getNotes from '../Composables/getNotes'

export default {
    props: ['studentid'],
    components: {NoteList},
    setup(props){
        const {notes, errorNote, loadNote} = getNotes(props.studentid)
        loadNote()

        return{ notes }
    }
    

}
</script>

<style>

</style>