<template>
    <option v-for="student in students" :key = student.id>
      {{student.FirstName}}
      </option>
    
</template>

<script>
import StudentOption from './StudentOption.vue'
export default {
    props:['students'],
    components: { StudentOption },
    setup(props){

        //console.log(students)

    }

}
</script>

<style>

</style>