<template>
  <nav class="blue-grey">
    <div class="nav-wrapper">
      <a href="#!" class="brand-logo">IcFEM</a>
      <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i></a>
      <ul v-if="user"  class="right hide-on-med-and-down">
        <li><router-link :to="{name: 'ViewAllStudents'}"><a>View All Students</a></router-link></li>
        <li><router-link :to="{name: 'AddStudent'}"><a>Add New Student</a></router-link></li>
        <li v-if="uk"><router-link :to="{name: 'Hubspot'}"><a>Link Students</a></router-link></li>
        <li v-if="uk"><a>View Links</a></li>
        <li v-if="uk"><a>Admin</a></li>
        <li class="red"><a @click="handleLogout">Logout</a></li>
      </ul>
      

      
      
    </div>
    
  </nav>
  <ul class="sidenav" id="mobile-demo">
        <li><router-link :to="{name: 'ViewAllStudents'}"><a class="black-text">View All Students</a></router-link></li>
        <li><router-link :to="{name: 'AddStudent'}"><a class="black-text">Add New Student</a></router-link></li>
        <li v-if="uk"><router-link :to="{name: 'Hubspot'}"><a class="black-text">Link Students</a></router-link></li>
        <li v-if="uk"><a class="black-text">View Links</a></li>
        <li v-if="uk"><a class="black-text">Admin</a></li>
        <li class="red"><a @click="handleLogout" class="white-text">Logout</a></li>
  </ul>



</template>

<script>
  
import { ref } from '@vue/reactivity'
import useLogout from '../Composables/useLogout'
import getUser from '../Composables/getUser'
import { onMounted } from '@vue/runtime-core';






export default {
  props: true,
    setup(){
      onMounted(() => {
        var elems = document.querySelectorAll('.sidenav');
        var instances = M.Sidenav.init(elems, {});
      })
      const {logout, error} = useLogout()
      const { user } = getUser()
        const loggedIn = ref(true)
        const uk = ref(true)


        const handleLogout = async () => {
          await logout()
          if(!error.value){
            console.log("logged out")
          }

        }




    return { loggedIn, uk, handleLogout, user }   
    }
    

}
</script>

<style>

</style>