import { ref } from "vue"
import { projectAuth } from "../firebase/config"
import router from "../router"

const user = ref(projectAuth.currentUser)

projectAuth.onAuthStateChanged((_user) => {
    console.log('user state change current user is: '+_user)
    user.value = _user
    if(!user.value){
        router.push({name: 'Login'})
    }
})

const getUser = () => {
    return { user }
}

export default getUser