import firebase from 'firebase/compat/app'
import "firebase/compat/firestore"
import "firebase/compat/auth"

const firebaseConfig = {
    apiKey: "AIzaSyA57LairSSCYmb7ernGWjYq-2TMf6LKx4I",
    authDomain: "child-sponsorship-1d6ef.firebaseapp.com",
    projectId: "child-sponsorship-1d6ef",
    storageBucket: "child-sponsorship-1d6ef.appspot.com",
    messagingSenderId: "1095889689762",
    appId: "1:1095889689762:web:40143f1bd91d3f63288bfb",
    measurementId: "G-PWT1C73VT8"
  };

  
  //init firebase
  firebase.initializeApp(firebaseConfig)

  //init firestore
  const projectAuth = firebase.auth()
  const projectFirestore = firebase.firestore()
  const timestamp = firebase.firestore.FieldValue.serverTimestamp

  export { projectFirestore, timestamp, projectAuth }