<template>
<div class="container">
   <div class="row">
    <form class="col s12" @submit.prevent>
      <div class="row">
          <h5 class="center">Registering <span v-if="!fname && !lname">a new Student</span><span v-else>{{ fname }} {{ lname }}</span></h5>
        <div class="input-field col s6">
          <input id="first_name" type="text" class="validate" v-model="fname">
          <label for="first_name">First Name</label>
        </div>
        <div class="input-field col s6">
          <input id="last_name" type="text" class="validate" v-model="lname">
          <label for="last_name">Last Name</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12 m6">
          <input id = "dob" type="text" class="datepicker" v-model.lazy="dob">
          <label for="dob">Date of Birth</label>
        </div>
        <div class="input-field col s12 m6">
          <input id="Address" type="text" class="validate" v-model="address">
          <label for="Address">Address</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12 m6">
        <select v-model="year" >
            <option value="pp 1">PP 1</option>
            <option value="pp 2">PP 2</option>    
            <option value="Grade 1">Grade 1</option>
            <option value="Grade 2">Grade 2</option>
            <option value="Grade 3">Grade 3</option>
            <option value="Grade 4">Grade 4</option>
            <option value="Grade 5">Grade 5</option>
            <option value="Grade 6">Grade 6</option>
            <option value="Grade 7">Grade 7</option>
            <option value="Grade 8">Grade 8</option>
            <option value="Form 1">Form 1</option>
            <option value="Form 2">Form 2</option>
            <option value="Form 3">Form 3</option>
            <option value="Form 4">Form 4</option>
            <option value="Higher Education Year 1">Higher Education Year 1</option>
            <option value="Higher Education Year 2">Higher Education Year 2</option>
            <option value="Higher Education Year 3">Higher Education Year 3</option>
            <option value="Higher Education Year 4">Higher Education Year 4</option>
        </select>
        <label>Starting Year</label>
        </div>
        <div class="input-field col s12 m6">
        <select v-model="boarder">
            <option value="Boarder">Boarder</option>
            <option value="Day Scholar">Day Scholar</option>    
        </select>
        <label>Boarding Status</label>
        </div>
      </div>
      <div class="row">
          <div class="input-field col s12 m6">
                    <textarea id="start" class="materialize-textarea" v-model="reason"></textarea>
                <label for="start">Reason for starting in this year.</label>
               
          </div>
          <div class="input-field col s12 m6">
                    <textarea id="background" class="materialize-textarea" v-model="background"></textarea>
                <label for="background">Background Details. Please include as much detail as possible.</label>
               
           
          </div>
        </div>
          <div class="row">
          <div class="input-field col s12 m6">
                    <textarea id="looking" class="materialize-textarea" v-model="forward"></textarea>
                <label for="looking">What is the child most looking forward to?</label>
               
          </div>
        <div class="input-field col s12 m6">
        <select v-model="dec" @change = "handleChange">
            <option value='DEC'>DEC</option>
            <option value='Other'>Other</option>    
        </select>
        <label>School</label>

        </div>
        </div>
        <div v-if="decRead" class="row">
        <div class="input-field col s12">
          <input id="school" type="text" class="validate" v-model="school">
          <label for="school">School Name</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12 m6">
        <select v-model="ortho" @change = "handleChangeOrtho">
            <option value=false>No</option>
            <option value=true>Yes</option>    
        </select>
        <label>Has this child received Orthopaedic treatment?</label>

        </div>
        <div v-if="orthoRead">
        <div class="input-field col s12 m6">
          <textarea id="orthoDetails" class="materialize-textarea" v-model="orthoDetails"></textarea>
        <label for="orthoDetails">Please give full details of the treatment</label>
        </div>
      </div>
        <div class="input-field col s12 m6">
        <select v-model="perms" @change = "handleChangePerms">
            <option value=false>No</option>
            <option value=true>Yes</option>    
        </select>
        <label>Does this child's family consent to their photo being used?</label>
        </div>
        </div>
<div class="row">
        
        <div class="file-field input-field col m6 s12">
      <div class="btn" style="width:100%">
        <span>Upload Photo of Child</span>  
        <input type="file" @change="handleFile" accept="image/*">
      </div>
    <div class="btn green" v-if="imgMsg"  style="width:100%; margin-top:5px;">
          <i class="large material-icons left">check</i>Photo ready to upload
        
      </div>
        </div>

        <div v-if="permsRead" class="file-field input-field col m6 s12">
      <div class="btn" style="width:100%">
        <span>Upload Photo of Consent Form</span>
        <input type="file"  @change="handleFile2" accept="image/*">
        </div>
        <div class="btn green" v-if="conMsg" style="width:100%; margin-top:5px;">
          <i class="large material-icons left">check</i><span>Consent form ready to upload</span>
        
      </div>
        </div>
 
        <div class="col m6 s12" >

        </div>
        </div>      
    </form>
    <div class="center-align">
    <button class="waves-effect waves-light btn" style="width:50%" @click="handleForm">Save Details</button>
    </div>
  </div>
</div>
</template>

<script>
const Swal = require('sweetalert2')
import { ref } from '@vue/reactivity';
import { onBeforeMount, onMounted, watch } from '@vue/runtime-core'
import { projectAuth, projectFirestore, timestamp } from '../firebase/config'
import {useRouter} from 'vue-router'
import onUpload from '../Composables/uploadImage'
  
export default {
    setup(){


    onMounted(() => {
        //init Materialize elements
        var elems = document.querySelectorAll('.datepicker')
        var instances = M.Datepicker.init(elems, {})
        var elems = document.querySelectorAll('select')
        var instances = M.FormSelect.init(elems, {})

        
    })

        const dec = ref()
        const decRead = ref(false)
        const orthoRead = ref(false)
        const ortho = ref()
        const perms = ref()
        const permsRead = ref(false)
        const router = useRouter()
        const image = ref()
        const image2 = ref()

        

        const fname = ref()
        const lname = ref()
        const dob = ref()
        const address = ref()
        const year = ref()
        const boarder = ref()
        const reason = ref()
        const background = ref()
        const forward = ref()
        const school = ref()
        const orthoDetails = ref()
        const school1 = ref()
        const orthoDetails1 = ref()
        var img
        var img2
        var upload
        var consent
        const imgMsg = ref(false)
        const conMsg = ref(false)

        const dataMissingError = ref(false)
        const dataMissingErrorText = ref("There is some data missing, please ensure all boxes are filled.")


        const handleFile = (e) =>{
            image.value = e.target.files[0]
            imgMsg.value = true
            //console.log(image)
            //onUpload(image)
            
        }

        const handleFile2 = (e) =>{
            image2.value = e.target.files[0]
            conMsg.value = true
        }

        const handleChange = () => {
            
            if(dec.value == 'Other'){
                decRead.value = true
            }else{
                decRead.value = false
            }
        }

        const handleChangeOrtho = () => {
            
            if(ortho.value == 'true'){
                orthoRead.value = true
            }else{
                orthoRead.value = false
            }
        }

        const handleChangePerms = () => {
            
            if(perms.value == 'true'){
                permsRead.value = true
            }else{
                permsRead.value = false
            }
        }

        const handleForm = async () => {
            


            if (dec.value == 'DEC'){
                school.value = 'DEC'
                school1.value = 'DEC'
            }else{
                school1.value = school.value
            }

            if (ortho.value == 'false'){
                orthoDetails1.value = 'Not Applicable'
            }else{
                orthoDetails1.value = orthoDetails.value
            }

            if(fname.value == null || lname.value == null || dob.value == null || address.value == null || year.value == null || boarder.value == null || reason.value == null || background.value == null || forward.value == null || school.value == null || school1.value == null || ortho.value == null || orthoDetails1.value == null || orthoDetails1.value == "" || fname.value == "" || lname.value == "" || dob.value == "" || address.value == "" || year.value == "" || boarder.value == "" || reason.value == "" || background.value == "" || forward.value == "" || school.value == "" || school1.value == "" || ortho.value == "" || orthoDetails1.value == ""){
                dataMissingError.value = true
                Swal.fire({
                    title: 'Error!',
                    text: dataMissingErrorText.value,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else if(isNaN(new Date(dob.value))){
                Swal.fire({
                    title: 'Error!',
                    text: 'Date is invalid',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }else{

            Swal.fire({
                    title: 'Uploading Images',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                })
                Swal.showLoading()

            if(image.value == null){
                upload = "No Photo"
            }else{
                img = await onUpload(image.value)
                upload = img.value
            }

            if(image2.value == null){
                consent = "No Photo"
            }else{
                img2 = await onUpload(image2.value)
                consent = img2.value
            }
            Swal.close()  
            
            var d = new Date()
            d.setMonth(d.getMonth() - 6)

            var userName = projectAuth.currentUser

            
            const childData = {
                FirstName: fname.value,
                LastName: lname.value,
                DOB: new Date(dob.value),
                Address: address.value,
                StartYear: year.value,
                BoardingStatus: boarder.value,
                YearReason: reason.value,
                Background: background.value,
                LookingForward: forward.value,
                School: school1.value,
                Ortho: ortho.value,
                OrthoDetails: orthoDetails1.value,
                Permission: perms.value,
                DateAdded: timestamp(),
                Photo: upload,
                Consent: consent,
                Sponsor: null,
                PP: null,
                Archive: false,
                AddedBy: userName.email
            }
            
            try{
                const response = await projectFirestore.collection('Students').add(childData)

                Swal.fire({
                    title: 'Success!',
                    text: 'Child data has been saved.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })

                router.push({name: 'Home'})


            }
            catch(err){

                 Swal.fire({
                    title: 'Error!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                
            }

            
                
           
               
            

            }
        
        }

        return{conMsg, imgMsg, handleFile2, onUpload, handleFile, dec, decRead, handleChange, handleChangeOrtho, orthoRead, ortho, perms, permsRead, handleChangePerms, fname, lname, dob, address, year, boarder, reason, background, forward, orthoDetails, school, handleForm, school1, orthoDetails1, dataMissingError, dataMissingErrorText }
    }

}
</script>

<style>

</style>