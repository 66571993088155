import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const getStudent = (id) => {
    const student = ref(null)
    const error = ref(null)
    const studentId = ref(null)

    const load = async() => {
      try{

        let response = await projectFirestore.collection('Students').doc(id).get()
        
        student.value = { ...response.data(), id: response.id
        }
        projectFirestore.collection('Students').doc(id).onSnapshot((snap)=>{
            let docs = snap.data()
            student.value = docs
            studentId.value = snap.id
            
          })
        
      }
      catch(err){
        error.value = err.message    
        console.log(error.value)    
      }

    }
    return{ student, error, load, studentId }
}

export default getStudent