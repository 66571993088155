import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'



const getSecondStudents = () => {
    const students = ref([])
    const error2 = ref(null)
    const resultSearch2 = ref([])
    const secondStudents = ref([])

    const load2 = async() => {
      try{

        const response = await projectFirestore.collection('Students').where('Archive', '==', false).orderBy("DateAdded", "desc")
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          students.value = docs
          

          secondStudents.value = students.value.filter(student => student.School != "DEC")
          secondStudents.value = secondStudents.value.filter(student => student.StartYear != "Higher Education Year 1")
          secondStudents.value = secondStudents.value.filter(student => student.StartYear != "Higher Education Year 2")
          secondStudents.value = secondStudents.value.filter(student => student.StartYear != "Higher Education Year 3")
          secondStudents.value = secondStudents.value.filter(student => student.StartYear != "Higher Education Year 4")
          resultSearch2.value = secondStudents.value
        })


      }
      catch(err){
        error2.value = err.message    
        console.log(error2.value)    
      }

    }
    return{ secondStudents, resultSearch2, error2, load2 }
}

export default getSecondStudents